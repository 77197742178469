import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  baseApiUrl = environment.baseUrl;
  resetPwdUrl = environment.resetPwdUrl;
  rpostTermsUrl = environment.rpostTermUrl;
  clientId = environment.clientId;
  version = environment.verson;
  authURL = environment.authURL;
  tokenURL = environment.tokenURL;
  ssoURL = environment.ssoURL;
  ssoEnabled = environment.ssoEnabled;
  googleSiteKey = environment.googleCaptchaSiteKey;
  googleSecretKey = environment.googleCaptchaSecretKey;
  rmailRegistrationLink = environment.rmailRegistrationLink;
  rsuitUrlLink = environment.RSuiteUrl;
  constructor() { }
}
