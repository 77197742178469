import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MouseEventsService {

  private mouseMoveSubject = new Subject<{ x: number, y: number }>();
  mouseMove$ = this.mouseMoveSubject.asObservable();

  private clickSubject = new Subject<MouseEvent>();
  click$ = this.clickSubject.asObservable();

  private scrollSubject = new Subject<Event>();
  scroll$ = this.scrollSubject.asObservable();

  constructor(private ngZone: NgZone) {
    this.initScrollListener();
  }

  private initScrollListener() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('wheel', (event: Event) => {
        this.scrollSubject.next(event);
      });
    });
  }


  emitMouseMove(position: { x: number, y: number }) {
    this.mouseMoveSubject.next(position);
  }

  emitMouseClick(event: MouseEvent) {
    this.clickSubject.next(event);
  }

}
