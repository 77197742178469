import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfigService } from "../app-config.service";
import { catchError } from "rxjs/operators";
import { AppError } from "./app-error";
import { Observable,  of } from 'rxjs';


@Injectable()
export class DataMFAApiService {

    baseUrl: string;
    clientId: string;
    authURL: string;
    tokenURL: string;
    ssoEnabledURL: string;

    constructor(private http: HttpClient, private config: AppConfigService) {
        this.baseUrl = this.config.baseApiUrl;
        this.clientId = this.config.clientId;
        this.authURL = this.config.authURL;
        this.tokenURL = this.config.tokenURL;
        this.ssoEnabledURL = this.config.ssoEnabled;

    }


    getMFALoginSetting(params: any) {

        var obj = {
            Client_Id: this.config.clientId,
            EmailAddress: params.emailAddress,
            Browser_Device: params.browser_Device,
            InfoExist: params.infoExist
        }

        var request = JSON.stringify(obj);

        var url = `${this.tokenURL}/api/v1/Login/Setting`;

        return this.http.post(url, request, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            catchError(this.handleError)
        );

    }

    generateMFAToken(params:any){

        var request = JSON.stringify(params);

        var url = `${this.tokenURL}/api/v1/MFA/Token`;

        return this.http.post(url, request, {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }).pipe(
            catchError(this.handleError)
        );

    }


    private handleError(err: HttpErrorResponse ): Observable<AppError> {
        const appError = new AppError(err);
        const errorStatus = err.status;
        const errorStatusText = err.statusText;
        const error: any = err.error;
        let errorMsg = '';
        let msgId = '';
    
        if (error != null && error != undefined) {
          if (error.Message !== undefined && Array.isArray(error.Message)) {
            errorMsg = err.error.Message[0].Message;
            msgId = err.error.Message[0].MessageId;
          } else {
            if (error.error_description) {
              errorMsg = error.error_description;
            } else {
              if (error.Message) {
                errorMsg = error.Message;
                msgId = (error.MessageId) ? error.MessageId : '';
              } else {
                errorMsg = errorStatusText;
              }
            }
          }
        }
    
        const retMsg = `Error (${errorStatus}): ${errorMsg}`;
        appError.Message = retMsg;
        appError.MessageId = msgId;
    
        return of(appError);
      }


}