import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AppConfigService } from "src/app/app-config.service";
import { AuthService } from "src/app/auth/auth.service";
import { AppError } from "src/app/common/app-error";
import { IresetPassword } from "src/app/models/reset-password.model";



@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPassword implements OnInit {

    resetForm: FormGroup;    
    isCodeValid: boolean = true; //S12-1176
    constructor(private appConfigService: AppConfigService, private authService: AuthService,
        private route: ActivatedRoute, private formBuilder: FormBuilder, private snackBar: MatSnackBar,private router: Router) {

    }
    
    logoURL= "";
    validPassword: boolean = false;
    validPasswordPattern: boolean = false;
    validConfirmPassword: boolean = false;
    validConfirmPatternPassword: boolean = false;
    validMatchPassword: boolean = false;
    resetCode: any;
    isPasswordReset: boolean = false;

    hasUppercaseFlag: boolean = false;//S12-1176
    hasLowercaseFlag: boolean = false;
    hasNumberFlag: boolean = false;
    hasSpecialCharFlag: boolean = false;
    isLengthValidFlag: boolean = false;
    showPassword: boolean = false;  
    showConfirmPassword: boolean = false; //S12-1176
    errorImageUrl = "";

    ngOnInit() {

        this.route.paramMap.subscribe((params: ParamMap) => {
          this.resetCode = params.get('Id');
          this.validateResetCode(this.resetCode);//S12-1176
         
        });

        // this.logoURL=this.appConfigService.logoUrl;
        this.logoURL = '../../assets/images/rmail-latest-logo.png';
       
    }

    buildForm() {

        this.resetForm = this.formBuilder.group({
            password: new FormControl('', [Validators.required,
                                        Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*]).{8,25}$"),
                                        Validators.minLength(8)]),
            confirmPassword: new FormControl('', [Validators.required,
                                        Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*]).{8,25}$"),
                                        Validators.minLength(8)])

        });

    }

    validateResetCode(code: string) {      //S12-1176
      this.authService.validatestring(code)
        .subscribe(resp => {
          if (resp instanceof AppError) {
            const msg: string = resp.Message;

            if (msg.includes("not valid") || resp.originalError.status == 400  || msg.includes("no longer valid")) {
               this.isCodeValid = false;
               this.errorImageUrl ='../assets/images/timeout-error.png';
            }
        } 
        this.buildForm();
        });
    }

    resetPassword(form: FormGroup) {

        if (this.resetForm.invalid || !this.isPasswordValid() || this.validMatchPassword) {

            var validForm = this.validateResetForm(this.resetForm);

            if (!validForm)
                return;
        }

        if (form.value.password != form.value.confirmPassword) {
            this.validMatchPassword = true;
            return;
        } 

        var obj = {
            password: decodeURIComponent(form.value.password),
            confirmPassword: decodeURIComponent(form.value.confirmPassword),
            code: this.resetCode
        }

        this.authService.resetPassword(obj.password, obj.confirmPassword, obj.code)
            .subscribe(resp => {

                // if (resp instanceof AppError) {
                //     const msg: string = resp.Message;

                //     // this.isPasswordReset = false;

                //     if (msg.includes("not valid") || resp.originalError.status == 400) {
                //         this.openSnackBar("Password reset link is no longer valid", "");
                //     }

                //     if (msg.includes("no longer valid") || resp.originalError.status == 400) {
                //          this.openSnackBar("Password reset link is no longer valid", "");
                //     }
                // }
                // else 
                if(resp instanceof IresetPassword){

                    var result = resp as IresetPassword;

                    if (result.Status == "Success") {
                        this.isPasswordReset = true;
                        // this.openSnackBar('Password reset successful', '');
                    }
                }
                else{
                    if (resp.includes("succeeded")) {
                        this.isPasswordReset = true;
                        // this.openSnackBar('Password reset successful', '');
                    }
                }

                this.buildForm();

            });

    }

    validateResetForm(resetForm: any) {

        if (resetForm.controls.password && (resetForm.controls.password.value != '' || resetForm.controls.password.value == ''))
            this.validPassword = true;

        if (resetForm.controls.confirmPassword && (resetForm.controls.confirmPassword.value != '' || resetForm.controls.confirmPassword.value == ''))
            this.validConfirmPassword = true;

        return false;

    }

    onPasswordBlur(event: any) {

        if ((this.resetForm.controls.password.errors?.pattern || this.resetForm.controls.password.errors?.minlength) && event.target.value != "")
          this.validPasswordPattern = true;
        else
          this.validPasswordPattern = false;

        if (this.resetForm.controls.password.value != this.resetForm.controls.confirmPassword.value && this.resetForm.controls.confirmPassword.value != "")
          this.validMatchPassword = true;
        else
          this.validMatchPassword = false;

        this.validPassword = event.value != "" ? false : true;

    }

    onConfirmPasswordBlur(event: any) {

        if ((this.resetForm.controls.confirmPassword.errors?.pattern || this.resetForm.controls.confirmPassword.errors?.minlength) && event.target.value != "")
          this.validConfirmPatternPassword = true;
        else
          this.validConfirmPatternPassword = false;

        if (this.resetForm.controls.password.value != "" && this.resetForm.controls.password.value != this.resetForm.controls.confirmPassword.value)
          this.validMatchPassword = true;
        else
          this.validMatchPassword = false;

        this.validConfirmPassword = event.valie != "" ? false : true;

    }

    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
          duration: 5000,
          verticalPosition: 'top'
        });
      }

       // S12-1176
      hasUppercase() { 
        const password = this.resetForm.controls.password.value;
        this.hasUppercaseFlag = /[A-Z]/.test(password);
        return this.hasUppercaseFlag;
      }

      hasLowercase() {
        const password = this.resetForm.controls.password.value;
        this.hasLowercaseFlag = /[a-z]/.test(password);
        return this.hasLowercaseFlag;
      }
    
      hasNumber() {
        const password = this.resetForm.controls.password.value;
        this.hasNumberFlag = /[0-9]/.test(password);
        return this.hasNumberFlag;
      }
    
      hasSpecialChar() {
        const password = this.resetForm.controls.password.value;
        this.hasSpecialCharFlag = /[#?!@$%^&*]/.test(password);
        return this.hasSpecialCharFlag;
      }
      
      isLengthValid() {
        const password = this.resetForm.controls.password.value;
        this.isLengthValidFlag = password.length >= 8;
        return this.isLengthValidFlag;
      }

      isPasswordValid() {
        return (
          this.hasUppercase() &&
          this.hasLowercase() &&
          this.hasNumber() &&
          this.hasSpecialChar() &&
          this.isLengthValid() 
        );
      }

         
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  } //S12-1176

}