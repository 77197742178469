import { DataApiService, IApiResponse } from './../common/data-api.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserSessionService } from '../common/user-session.service';

@Injectable()
export class TrackingService {

    esignURL = 'api/Tracking/esign';
    messageURL = 'api/Tracking/';

    constructor(
        private user: UserSessionService,
        private data: DataApiService) { }

    getEsignDetails(searchData: any): Observable<any> {
        const url = this.esignURL;
        return this.data.apiGetEsignDetails(url, searchData);
    }

    apiGetTrackingMessageDetails(trackingId: string) {

        const url = this.messageURL + trackingId;

        return this.data.apiGetMessageDetails(url);
    }

    getTrackingAccess() {
        const url = this.messageURL + "WebTrackingAccess";
        return this.data.apiGetTrackingAccess(url);
    }

    apicancelTransaction(data: any) {
        const url = this.messageURL + "CancelTransaction";
        return this.data.apiCancelTransaction(url, data);
    }

    apiGetMessageIds(data:any){
        const url = this.messageURL + "GetMessageLookUp";
        return this.data.apiGetMessageIds(url, data);
    }

    apiGetSenderRecipients(data:any){
        const url = this.messageURL + "GetMailAddressLookUp";
        return this.data.apiGetSenderRecipients(url, data);
    }

    apiDownloadCSV(data: any) {
        const url = this.messageURL + "DownloadCSV";
        return this.data.apiDownloadCSV(url, data);
    }

    sendManualReminder(data:any){
        const url = this.messageURL + "SendManualReminder";
        return this.data.sendManualReminder(url, data);
    }

    getReminderList(data:any){
        const url = this.messageURL + "ESignRemindersSent";
        return this.data.getReminderList(url, data);
    }

}