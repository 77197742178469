import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { format, subMinutes } from 'date-fns';
import { of } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { AppError } from './app-error';
import { MIME_JSON } from 'mat-table-exporter';

@Injectable({
    providedIn: 'root',
})

export class RefreshTokenService {

    constructor(private authService: AuthService, private router: Router) {

    }

    timer: any;

    getRefreshToken() {
        
        if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {
            var userDetails = JSON.parse(localStorage.getItem('user') || '{}');
            
            var date = format(new Date(), 'MM/dd/yyyy HH:mm:ss');

            var expireDate = userDetails.loginToken != null && userDetails.loginToken != undefined ? format(new Date(userDetails.loginToken[".expires"]), 'MM/dd/yyyy HH:mm:ss') : "";
            var expires_In = userDetails.loginToken != null && userDetails.loginToken != undefined ? (userDetails.loginToken.expires_in - 10) * 1000 : 0;
            var expdate = subMinutes(new Date(expireDate), 1);

            this.checkTokenExpiration();

            if (new Date(date) > new Date(expdate) && localStorage.getItem('user') !== null && localStorage.getItem('user') !== undefined) {

                //     console.log('refresh expiration date', expireDate);
                // console.log('refresh exp date minus 1 min', expdate);
                // console.log('current date', date);

                var minutes = new Date(expireDate).getMinutes() - new Date(date).getMinutes();

                return this.authService.getRefreshTokenlogin().subscribe((resp: any) => {
                    //console.log("refresh token success.", resp);  
                    if (!(resp instanceof AppError)) {
                        var result = resp;

                        var reload = JSON.parse(localStorage.getItem('reload') || '""');

                        if (reload != "" && reload != null && reload === true) {
                            
                            window.location.reload();
                        }
                    }
                    else {
                        var error = resp.originalError != null && resp.originalError != undefined && resp.originalError.error != null && resp.originalError.error != undefined && resp.originalError.error.message != null && resp.originalError.error.message != undefined ? resp.originalError.error.message.messageCode : "";
                        var statusCode = resp.originalError != null && resp.originalError != undefined && resp.originalError.error != null && resp.originalError.error != undefined && resp.originalError.error.statusCode != null && resp.originalError.error.statusCode != undefined ? resp.originalError.error.message.statusCode : "";
                        //if (error == "RCAP-1000" || error == "RCAP-1001" || statusCode == 401) {

                        var azureAccess = JSON.parse(localStorage.getItem('azureAccess') || '""');

                        this.authService.logout();

                        if (azureAccess != null && azureAccess != "")
                            localStorage.setItem('isUnAuthorized', "Y");
                        else
                            localStorage.setItem('isUnAuthorized', "N");

                        //console.log("logout from refresh token service");
                        this.stopTimer();
                        this.router.navigate(['/login']);
                        //console.log('after router navigate');
                        //}
                    }

                });

            }

            return of();
        }
    }

    checkTokenExpiration() {
        
        if (localStorage.getItem('user') != null && localStorage.getItem('user') != undefined) {

            var userDetails = JSON.parse(localStorage.getItem('user') || '{}');

            var date = format(new Date(), 'MM/dd/yyyy HH:mm:ss');

            var expireDate = userDetails.loginToken != undefined ? format(new Date(userDetails.loginToken[".expires"]), 'MM/dd/yyyy HH:mm:ss') : "";
            var refreshTokenExpire = userDetails.loginToken != undefined && userDetails.loginToken[".refresh_expires"] != undefined ? format(new Date(userDetails.loginToken[".refresh_expires"]), 'MM/dd/yyyy HH:mm:ss') : "";
            console.log("token expire date: ", expireDate);

            if (new Date(date) > new Date(refreshTokenExpire)) {
                
                this.authService.logout();
                this.stopTimer();
                this.router.navigate(['/login']);
            }
        }
    }

    startTimer() {

        this.stopTimer();
        this.getRefreshToken();

       this.timer= setTimeout(() => {
        //console.log("timer settimeout");
            this.startTimer();
        }, 100);

        //this.timer = setTimeout(() => this.getRefreshToken().subscribe(), 100);

        // setTimeout(function myTimer(this: any) {
        //     //this.getRefreshToken();
        //     console.log("timer settimeout");
        //     setTimeout(myTimer, 100);
        // }, 100);
    }

    stopTimer() {
        clearTimeout(this.timer);
    }

}
