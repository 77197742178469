import { Component, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { DataApiService } from "src/app/common/data-api.service";
import { MessageDetail, IMessageDetail, Recipients, Status, IreminderResponseData } from "src/app/models/tracking-details.model";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { RecipientDetails } from 'src/app/models/tracking-grid-details.model';
import { MatStepper } from '@angular/material/stepper';
import { ModalPopupService } from 'src/app/providers/modalPopup.service';
import { CancelTransactionComponent } from '../cancel-transaction/cancel-transaction.component';
import { MatDialogRef } from '@angular/material/dialog';
import { addDays, format } from 'date-fns'
import { AuthService } from 'src/app/auth/auth.service';
import { TrackingService } from 'src/app/trackingservice/tracking.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IAppUser } from 'src/app/auth/iapp-user';
import { RefreshTokenService } from 'src/app/common/refreshToken.service';
import { AppError } from 'src/app/common/app-error';
import { UserSessionService } from 'src/app/common/user-session.service';
import { MouseEventsService } from 'src/app/common/mouseevents.service';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
    selector: 'app-message-details',
    templateUrl: './message-details.component.html',
    styleUrls: ['./message-details.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})

export class MessageDetails implements OnInit {

    @ViewChild('MatPaginator', { static: false }) paginator: MatPaginator;
    @ViewChildren('innerTables') innerTables: QueryList<MatTable<Status>>;
    @ViewChild('stepper') stepper: MatStepper;
    USERS: Recipients[];
    public todayDate = format(new Date(new Date()), 'dd/MM/yyyy');
    Status: Status[];
    dtDateCompleted: string;
    dtDateSent: string;
    boolDeclineorCancel: boolean = false;
    boolCancelTransaction: boolean = false;
    boolSignatureInprogress: boolean = false;
    boolDelivered: boolean = false;
    boolsentForSignature: boolean = false;
    boolDeliveryFailure: boolean = false;
    dataSource: MatTableDataSource<Recipients>;
    usersData: Recipients[] = [];
    strStatus: string;
    reminderSentdestinationShortUrl: any;
    columnsToDisplay = [
        // { field: 'action', header: 'Action' },
        // { field: 'recipientName', header: 'Recipient Name' },
        // { field: 'currentStatus', header: 'Current Status' },
        // { field: 'statusUpdated', header: 'Status Updated On (UTC)' },
        // { field: 'message', header: 'Message' }


        { field: 'action', header: 'Action' },
        { field: 'address', header: 'Recipient Name' },
        { field: 'eSignStatus', header: 'Current Status' },
        { field: 'actionDate', header: 'Status Updated (UTC)' },
        { field: 'declineReason', header: 'Message' },
        { field: 'senderReminder', header: '' }
    ];
    innerDisplayedColumns = [
        { field: 'status', header: 'Status' },
        { field: 'statusUpdated', header: 'Status Updated (UTC)' },
        { field: 'ipAddress', header: 'IP Address' }
    ];
    expandedElement: any[] = [];

    headers: string[] = this.columnsToDisplay.map(x => x.field);
    innerheaders: string[] = this.innerDisplayedColumns.map(x => x.field);

    trackingId: any;
    dialogRef: MatDialogRef<any>;
    isLinear: boolean = true;
    isEditable: boolean = false;

    constructor(private dataAPI: DataApiService, private router: Router, private cd: ChangeDetectorRef,
        private route: ActivatedRoute, private modalPopupService: ModalPopupService, private authService: AuthService,
        private trackingService: TrackingService, public snackBar: MatSnackBar,
        private refreshToken: RefreshTokenService, private userService: UserSessionService,
        private cdr: ChangeDetectorRef, private mouseEventService: MouseEventsService,
        private appconfig: AppConfigService) {


    }

    errMsg: boolean;
    public messageDetail = new MessageDetail;
    public messageDetails = new MessageDetail;
    public receipientDetails: Recipients[];
    RecipientAddress: any = [];
    selectedIndex: number = -1;
    steps: any;
    public orderStatus = "In Progress";
    externallogin: any;
    showDropdown = false;
    innerGridData: Status[];

    ngOnInit() {


        this.route.paramMap.subscribe((params: ParamMap) => {
            this.trackingId = params.get('trackingId');
        });

        if (Number(localStorage.getItem('tabSelected')) == 0 && (localStorage.getItem('AdminAccess')) == "true")
            this.boolCancelTransaction = true;

        this.externallogin = JSON.parse(localStorage.getItem('user') || '{}') as IAppUser;

        this.messageDetailsData();

        this.mouseEventService.mouseMove$.subscribe(event => {

            // Send the message
            const targetOrigin = this.appconfig.rsuitUrlLink;
            window.parent.postMessage({ event: 'MouseClick', message: 'TrackEsign' }, targetOrigin);

        });

        this.mouseEventService.click$.subscribe(event => {

            // Send the message
            const targetOrigin = this.appconfig.rsuitUrlLink;
            window.parent.postMessage({ event: 'MouseClick', message: 'TrackEsign' }, targetOrigin);

        });

        this.mouseEventService.scroll$.subscribe((event: Event) => {

            // Send the message
            const targetOrigin = this.appconfig.rsuitUrlLink;
            window.parent.postMessage({ event: 'MouseClick', message: 'TrackEsign' }, targetOrigin);

        });

    }

    messageDetailsData() {

        this.trackingService.apiGetTrackingMessageDetails(this.trackingId)
            .subscribe((resp: any) => {

                let completed: number = 0;
                let inprogress: number = 0;
                let sentforsignature: number = 0;
                let setPresetPassword: number = 0;
                let deliveryFailed: number = 0;
                let key: number = 0;

                const response = resp.resultContent[0];

                if (resp != null && resp != undefined && resp.success
                    && response != null && response != undefined && response != "") {

                    this.messageDetail = response;

                    this.messageDetail.serviceType = this.getServiceType(this.messageDetail.serviceType);
                    this.messageDetail.esignExpirationDate = format(addDays(new Date(this.messageDetail.dateSent), this.messageDetail.esignExpirationDays), 'dd/MM/yyyy p') + " (UTC)";
                    this.messageDetail.dateSent = format(new Date(this.messageDetail.dateSent), 'dd/MM/yyyy p') + " (UTC)";

                    this.messageDetail.esignSequential = response.esignSequential == false ? "No" : "Yes";
                    this.receipientDetails = this.messageDetail.recipients;

                    this.USERS = this.messageDetail.recipients;


                    this.messageDetail.recipients.forEach((recipient: any) => {

                        this.RecipientAddress.push(recipient.address);

                        let dtContractSigned = recipient.timeSigned == null ? "-" : format(new Date(recipient.timeSigned), "dd/MM/yyyy p");
                        let dtContractViewed = recipient.linkClickedTime == null ? "-" : format(new Date(recipient.linkClickedTime), "dd/MM/yyyy p");
                        let dtContractDeclined = recipient.declineTime == null ? "-" : format(new Date(recipient.declineTime), "dd/MM/yyyy p");


                        recipient.timeSent = format(new Date(recipient.timeSent), "dd/MM/yyyy p");

                        var presetStatus = recipient.eSignStatus === 'EmailSentToSetEncryptionPassword' ? "Awaiting user to set preset password" : "Sent For Signature";

                        let userTestStatus: { status: string, statusUpdated: string, ipAddress: string }[] = [
                            { "status": presetStatus, "statusUpdated": recipient.timeSent, "ipAddress": "NA" },
                            { "status": "Document Viewed", "statusUpdated": dtContractViewed, "ipAddress": recipient.linkClickedIp != null && recipient.linkClickedIp != "" ? recipient.linkClickedIp : "-" },
                            // { "status":recipient.eSignStatus=="ContractDeclined" || recipient.eSignStatus=="CancelledTransation"?"Document Declined": "Document Signed", "statusUpdated": dtContractSigned, "ipAddress": recipient.ipAddress ?? "-" }
                            {
                                "status": recipient.eSignStatus == "ContractDeclined" ? "Document Declined" : "Document Signed",
                                "statusUpdated": recipient.eSignStatus == "ContractDeclined" ? dtContractDeclined : dtContractSigned,
                                "ipAddress": recipient.eSignStatus == "ContractDeclined" ? recipient.declineIp ?? "-" : (recipient.eSignStatus == "CancelledTransation" ? recipient.cancelledIp ?? "-" : recipient.ipAddress ?? "-")
                            }

                        ]

                        recipient.status = userTestStatus;


                        recipient.statusText = recipient.eSignStatus == null ? 'Completed' : recipient.eSignStatus.replace(/([a-z])([A-Z])/g, '$1 $2');
                        if (recipient.eSignStatus === 'ContractSigned' || recipient.eSignStatus === 'ContractDeclined' || recipient.eSignStatus === 'CannotSignAsAlreadyDeclined') {
                            completed = completed + 1;

                            if (recipient.eSignStatus === 'ContractDeclined') {
                                recipient.eSignStatus = 'Contract Declined';
                                this.boolDeclineorCancel = true;
                                recipient.actionDate = recipient.declineTime != null && recipient.declineTime != "" ? format(new Date(recipient.declineTime), "dd/MM/yyyy p") : "N/A";
                            }

                            if (recipient.eSignStatus === 'ContractSigned') {
                                recipient.eSignStatus = 'Contract Signed';
                                recipient.actionDate = recipient.timeSigned != null && recipient.timeSigned != "" ? format(new Date(recipient.timeSigned), "dd/MM/yyyy p") : "N/A";
                            }

                            // if(recipient.eSignStatus !== 'CannotSignAsAlreadyDeclined')
                            //     recipient.actionDate = recipient.timeSigned != null && recipient.timeSigned != "" ? format(new Date(recipient.timeSigned), "dd/MM/yyyy p") : "N/A";
                        }
                        else if (recipient.eSignStatus === 'ContractViewed') {
                            recipient.eSignStatus = 'Contract Viewed';
                            inprogress = inprogress + 1;
                            recipient.actionDate = dtContractViewed;
                        } else if (recipient.eSignStatus === 'SentForSignature') {
                            recipient.eSignStatus = 'Sent For Signature';
                            sentforsignature = sentforsignature + 1;
                            recipient.actionDate = recipient.timeSent;
                        }
                        else if (recipient.eSignStatus === "WaitingForPreceedingSigner") {
                            recipient.eSignStatus = 'Waiting For Preceeding Signer';
                            sentforsignature = sentforsignature + 1;
                            recipient.actionDate = recipient.timeSent;
                        }

                        else if (recipient.eSignStatus === 'CancelledTransation') {
                            recipient.eSignStatus = 'Cancelled Transaction';
                            completed = completed + 1;
                            this.strStatus = "Cancelled";
                            this.boolDeclineorCancel = true;
                            recipient.actionDate = recipient.cancelledTime != null && recipient.cancelledTime != undefined ? format(new Date(recipient.cancelledTime), 'dd/MM/yyyy p') : "N/A";
                        }
                        else if (recipient.eSignStatus === 'InvalidAddressOrDeliveryFailed') {
                            recipient.eSignStatus = 'Invalid Address or Delivery Failed';
                            sentforsignature = sentforsignature + 1;
                        }
                        else if (recipient.eSignStatus === 'ErrorDuringSigning') {
                            recipient.eSignStatus = 'Error During Signing';
                            sentforsignature = sentforsignature + 1;
                        }
                        else if (recipient.eSignStatus === 'ErrorDuringAnnotationProcessing') {
                            recipient.eSignStatus = 'Error During Annotation Processing';
                            sentforsignature = sentforsignature + 1;
                        }
                        else if (recipient.statusText === 'Completed') {
                            // recipient.progress = 100;
                            // recipient.progressType = 'danger';
                            completed = completed + 1;
                        }
                        else if (recipient.eSignStatus === 'EmailSentToSetEncryptionPassword') {
                            recipient.eSignStatus = 'Email Sent to Set Encryption Password';
                            this.strStatus = "Email Sent to Set Encryption Password";
                            setPresetPassword = setPresetPassword + 1;
                        }
                        else if (recipient.eSignStatus === 'Failed') {
                            recipient.eSignStatus = 'Delivery Failed';
                            this.boolDeliveryFailure = true;
                            deliveryFailed = deliveryFailed + 1;
                            recipient.actionDate = recipient.timeSent;
                        }
                        // else {
                        //     if (recipient.ESignStatus === 'WaitingForPreceedingSigner') {
                        //         recipient.secondStatus = recipient.statusText;
                        //         recipient.statusText = 'Not Sent';
                        //     }
                        //     recipient.progress = 0;
                        // }

                        if (recipient.maxDailyManualRemindersExceeds || recipient.maxManualRemindersExceeds)
                            recipient.Enabled = true;


                    });

                }
                else {

                    this.refreshToken.stopTimer();
                    this.authService.logout();

                    var azureAccess = JSON.parse(localStorage.getItem('azureAccess') || '""');

                    if (azureAccess != "" && azureAccess != null) {
                        this.authService.azureLogOut();
                    }
                    else {
                        this.router.navigate(['/login']);
                    }

                    return;
                }

                this.dtDateCompleted = this.messageDetail.recipients.map(function (e) { return e.linkClickedTime; }).sort().reverse()[0];

                if (completed == 0 && inprogress == 0 && sentforsignature == 0 && setPresetPassword == 0 && deliveryFailed == 0) {
                    this.boolDelivered = false;
                    this.boolsentForSignature = false;
                    this.boolSignatureInprogress = false;
                    this.boolCancelTransaction = true;
                    this.boolDeclineorCancel = true;

                }
                else if (completed == this.messageDetail.recipients.length) {

                    this.dtDateCompleted = this.messageDetail.recipients.map(function (e) { return e.timeSigned; }).sort().reverse()[0];

                    this.boolDelivered = true;
                    this.boolsentForSignature = true;
                    this.boolSignatureInprogress = false;
                    this.boolCancelTransaction = true;
                    if (this.boolDeclineorCancel == true) {

                        if (this.strStatus == "Cancelled") {
                            var cancelledDate = this.messageDetail.recipients.filter(function (e) { return e.cancelledTime != null });

                            if (cancelledDate != null && cancelledDate != undefined) {
                                this.dtDateCompleted = cancelledDate[0].cancelledTime;
                            }
                        }
                        else {
                            this.strStatus = "Terminated";

                            var terminatedDate = this.messageDetail.recipients.filter(function (e) { return e.declineTime != null; });

                            if (terminatedDate != null && terminatedDate != undefined)
                                this.dtDateCompleted = terminatedDate[0].declineTime;
                        }

                    }
                    else
                        this.strStatus = "Completed";
                }
                else if (inprogress > 0 || (completed != this.messageDetail.recipients.length && sentforsignature != this.messageDetail.recipients.length && setPresetPassword != this.messageDetail.recipients.length && deliveryFailed != this.messageDetail.recipients.length)) {
                    this.dtDateCompleted = this.messageDetail.recipients.map(function (e) { return e.timeSigned; }).sort()[0];
                    if (this.dtDateCompleted == null) {

                        this.dtDateCompleted = this.messageDetail.recipients.map(function (e) { return e.linkClickedTime; }).sort()[0];
                    }

                    this.boolsentForSignature = true;

                    if (this.boolDeclineorCancel == true) {
                        this.strStatus = "Terminated";
                        this.boolCancelTransaction = true;
                        this.boolDelivered = true;
                        this.boolSignatureInprogress = false;
                    }
                    else if (this.boolDeliveryFailure == true) {
                        this.strStatus = "Delivery Failed";
                        this.boolCancelTransaction = false;
                        this.boolDeliveryFailure = true;
                        this.boolDeclineorCancel = true;
                    }
                    else {
                        this.boolDelivered = false;
                        this.boolSignatureInprogress = true;
                        this.strStatus = "Signature In Progress";
                    }
                }
                else if (sentforsignature > 0) {
                    this.boolDelivered = false;
                    this.boolsentForSignature = true;
                    this.boolSignatureInprogress = false;
                    this.strStatus = "Submitted For Signature";
                }
                else if (setPresetPassword > 0) {
                    this.boolDelivered = false;
                    this.boolsentForSignature = true;
                    this.boolSignatureInprogress = false;

                    this.strStatus = "Email Sent to Set Encryption Password";
                }
                else if (deliveryFailed > 0) {
                    this.boolCancelTransaction = false;
                    this.boolDeliveryFailure = true;
                    this.boolDeclineorCancel = true;
                    this.strStatus = "Delivery Failed";
                }
                //  alert(this.dtDateCompleted);

                this.dtDateCompleted = this.dtDateCompleted != null && this.dtDateCompleted != "" ? format(new Date(this.dtDateCompleted), "dd/MM/yyyy p") + " (UTC)" : "N/A";

                if (this.boolDeliveryFailure && this.boolCancelTransaction == false) {

                    this.boolCancelTransaction = true;

                    this.steps = {
                        statuesName: ["Submitted For Signature", "", "Delivery Failed"],
                        Status: [this.boolDeliveryFailure, this.boolDeliveryFailure, this.boolDeliveryFailure],
                        ActionTime: [this.messageDetail.dateSent, null, this.messageDetail.dateSent]
                    };
                }
                else {

                    this.steps = {
                        statuesName: [setPresetPassword == this.messageDetail.recipients.length ? "Email Sent to Set Encryption Password" : "Submitted For Signature", "Signature In Progress", (this.strStatus == "Cancelled" ? "Cancelled" : (this.strStatus == "Terminated" ? "Terminated" : "Completed"))],
                        Status: [this.boolsentForSignature, this.boolSignatureInprogress, this.boolDelivered],
                        //Status:[true,true,true],
                        ActionTime: [this.messageDetail.dateSent, this.dtDateCompleted, this.dtDateCompleted]
                    };
                }
                //this.selectedIndex = 1;
                this.dataSource = new MatTableDataSource(this.USERS);
                this.dataSource.paginator = this.paginator;

            });
    }

    getServiceType(esignType: string) {

        var esingTypeValue: string = "";

        switch (esignType) {
            case "EPaper":
                esingTypeValue = "E-Paper";
                break;
            case "SmartTags":
                esingTypeValue = "Tags";
                break;
            case "SecureSmartTags":
                esingTypeValue = "Secure Tags";
                break;
            case "SecureEPaper":
                esingTypeValue = "Secure E-Paper";
                break;
            default:
                esingTypeValue = "";
                break;
        }

        return esingTypeValue;
    }

    loadData() {

        this.selectedIndex = 1;

        // this.USERS.forEach(user => {
        //     if (user.statuses && Array.isArray(user.statuses) && user.statuses.length) {
        //         this.usersData = [...this.usersData, { ...user, statuses: new MatTableDataSource(user.statuses) }];
        //     } else {
        //         this.usersData = [...this.usersData, user];
        //     }
        // });
        this.dataSource = new MatTableDataSource(this.usersData);
        this.dataSource.paginator = this.paginator;
    }

    loadTracking() {

        this.router.navigate(['/trackingesign']);
    }

    toggleRow(row: any) {

        const index = this.expandedElement.findIndex(x => x.destinationShortURL == row.destinationShortURL);

        var requestObj = {
            messageId: this.messageDetail.messageId,
            destinationKey: row.destinationShortURL
        };

        row.expanded = false;

        if (index == -1) {

            this.trackingService.getReminderList(requestObj)
                .subscribe(resp => {

                    if (resp instanceof AppError) {

                    }
                    else {

                        var result = resp as IreminderResponseData;

                        if (result.resultContent.remindersSent != null &&
                            result.resultContent.remindersSent.length > 0) {

                            var date: any = [];

                            // Sort reminders array by reminderSentOn property
                            result.resultContent.remindersSent.sort((a, b) => new Date(a.reminderSentOn).getTime() - new Date(b.reminderSentOn).getTime());

                            result.resultContent.remindersSent.forEach((item, index) => {

                                date.push(format(new Date(item.reminderSentOn), "dd/MM/yyyy p"));
                            });

                            if (row.status.findIndex((f: any) => f.status.toLowerCase() === 'reminders sent') == -1) {
                                row.status.push({
                                    "ipAddress": "-",
                                    "status": "Reminders Sent",
                                    "statusUpdated": date,
                                });
                            }
                            else if (row.status.findIndex((f: any) => f.status.toLowerCase() === 'reminders sent') > -1){
                                row.status.splice(row.status.findIndex((f: any) => f.status.toLowerCase() === 'reminders sent'),1);
                                row.status.push({
                                    "ipAddress": "-",
                                    "status": "Reminders Sent",
                                    "statusUpdated": date,
                                });
                            }
                        }

                        row.expanded = true;

                        if (this.dataSource.data.findIndex(f => f.destinationShortURL === row.destinationShortURL) > -1) {
                            this.dataSource.data[this.dataSource.data.findIndex(f => f.destinationShortURL === row.destinationShortURL)].status = row.status;
                        }

                        this.innerGridData = row.status;

                        if (index === -1) {
                            this.expandedElement.push(row);
                        } else {
                            this.expandedElement.splice(index, 1);
                        }

                        this.cdr.detectChanges();
                    }
                });
        }
        else {

            this.expandedElement.splice(index, 1);
        }

    }

    isExpanded(row: any): string {

        if (
            this.expandedElement.findIndex(x => x == row) !== -1
        ) {
            return 'expanded';
        }
        return 'collapsed';
    }

    isExpansionDetailRow = (i: number, row: Object) => {
        return row.hasOwnProperty('detailRow');
    };

    sendReminder(rowItem: any) {

        var requestObj = {
            messageId: this.messageDetail.messageId,
            DestinationKey: rowItem.destinationShortURL
        }

        this.trackingService.sendManualReminder(requestObj)
            .subscribe((resp: any) => {

                if (resp instanceof AppError) {

                }
                else {

                    this.reminderSentdestinationShortUrl = rowItem.destinationShortURL;
                    rowItem.Enabled = true;
                    var res = resp;

                    if (res.resultContent != null && res.resultContent != undefined && res.resultContent != ""
                        && res.resultContent == 'Reminder already published for the day') {
                        this.openSnackBar("Pre-set reminder has already been sent for the day.", "");
                        return;
                    }

                    this.openSnackBar("Email reminder sent successfully.", "","green-snackbar");
                }

            });
    }

    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    print() {
        this.closeDropdown();
        this.userService.timeOut();
    }

    logout() {

        this.refreshToken.stopTimer();

        if (this.userService.getAzureAccess()) {

            this.authService.azureLogOut();
        }
        else {
            this.authService.getRevokeToken().subscribe((resp: any) => {

                if (!(resp instanceof AppError)) {
                    this.authService.logout();
                    this.router.navigate(['/login']);
                }
            });
        }
    }

    cancelTranscation() {

        this.dialogRef = this.modalPopupService.openPopup<CancelTransactionComponent>(
            CancelTransactionComponent,
            { messageId: this.messageDetail.messageId, trackingKey: this.trackingId }
        );
        this.dialogRef.afterClosed().subscribe(result => {

            if (result.toLowerCase() == "success") {
                this.openSnackBar("Transaction successfully cancelled", "", "green-snackbar");
                this.router.navigate(['/trackingesign']);
            }
            else if (result.toLowerCase() == "failure") {
                this.openSnackBar("Error occured while cancelling the transaction", "");
            }
            else {
                this.openSnackBar(result, "", "green-snackbar");
                this.router.navigate(['/trackingesign']);
            }

        });
    }

    openSnackBar(message: string, action: string, msgColor: string = 'red-snackbar') {
        this.snackBar.open(message, action, {
            duration: 5000,
            verticalPosition: 'top',
            panelClass: [msgColor]
        });
    }
    //S12-1194
    toggleDropdown() {
        this.showDropdown = !this.showDropdown;
    }
    //   //To close dropdown when click on outside
    @HostListener('document:click', ['$event'])
    handleOutsideClick(event: Event): void {
        const target = event.target as HTMLElement;
        const dropdownElement = document.querySelector('.dropdown');

        if (this.showDropdown && !dropdownElement?.contains(target)) {
            this.closeDropdown();
        }
    }

    closeDropdown(): void {
        this.showDropdown = false;
    }

    onMouseMove(event: MouseEvent) {

        if (window.origin == this.appconfig.resetPwdUrl)
            this.mouseEventService.emitMouseMove(event);
      }
    
      onHandleClick(event: MouseEvent){

          if (window.origin == this.appconfig.resetPwdUrl)
              this.mouseEventService.emitMouseClick(event);
      }

}

