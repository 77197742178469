import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpHeaders
} from '@angular/common/http';
import { AuthService } from './auth.service';



@Injectable({
    providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const url = req.url;
        if (this.isSkipHeader(url)) {
            return next.handle(req);
        }
        const authToken = `Bearer ${this.auth.getToken()}`;
        
        const userId = JSON.parse(localStorage.getItem('user') || '{}');
        if (url.endsWith('Upload')) {
            const uploadReq = req.clone({
                headers: new HttpHeaders({
                    'Accept': 'multipart/form-data',
                    'Authorization': authToken
                })
            });
            return next.handle(uploadReq);
        }
        
        const authReq = req.clone({
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': authToken,
                'Accept-Language': '*',
                'UserID': userId.userEmail != null && userId.userEmail != undefined ? userId.userEmail : ""
            })
        });
        return next.handle(authReq);

    } // interceptor

    private isSkipHeader(url: any) {
        
        if ((url.endsWith('/userregistration')) || (url.endsWith('/auth/Token')) 
            || (url.endsWith('/auth/ValidateToken')) || (url.endsWith('/auth/RefreshToken')) 
            || (url.endsWith('/auth/ExtToken')) || (url.endsWith('/auth/Token/Revoke')) 
            || (url.includes('ipv4.jsonip')) || (url.includes('/Login/Setting'))
            || (url.includes('/MFA/Token'))
            ) {
                
            return true;
        } else {
            
            return false;
        }
    }

} // class
