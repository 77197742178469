import { Injectable } from "@angular/core";
import { ConversionUtils } from "turbocommons-ts";


@Injectable({
    providedIn: 'root',
})


export class UtilsService {

    constructor(){
        
    }


    ConvertStringToBase64(originalString: string) {

        return ConversionUtils.stringToBase64(originalString);
    }

    ConvertBase64ToString(base64String: string) {

        return ConversionUtils.base64ToString(base64String);
    }
}