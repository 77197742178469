import { Injectable } from '@angular/core';
import { IAppUser, IBrandContent } from './../auth/iapp-user';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  constructor() { }

  getToken() {
    const user = this.getUser();
    if (user) {
      return user.token;
    }
  }

  setToken(token: string): void {
    localStorage.setItem('token', JSON.stringify(token));
  }


  getUser() {
    if (localStorage.getItem('user') !== undefined && localStorage.getItem('user') !== null) {
      return JSON.parse(localStorage.getItem('user') || '{}');
    }
  }

  setUser(user: IAppUser): void {
    localStorage.setItem('user', JSON.stringify(user));
  }

  setBrand(content: IBrandContent): void {
    localStorage.setItem('brand', JSON.stringify(content));
  }

  getBrand(): IBrandContent {
    if (localStorage.getItem('brand') !== undefined && localStorage.getItem('brand') !== null) {
      return JSON.parse(localStorage.getItem('brand')||'{}');
    } else {
      return {} as IBrandContent;
    }
  }

  // setLanguages(list) {
  //   const key = 'language';
  //   localStorage.setItem(key, JSON.stringify(list));
  // }

  setAppLanguage(lang: string) {
    localStorage.setItem('appLang', lang);
  }

  getLanguages() {
    const key = 'language';
    if (localStorage.getItem(key) !== undefined && localStorage.getItem(key) !== null) {
      return JSON.parse(localStorage.getItem(key) || '{}');
    } else {
      return null;
    }
  }

  getAppLanguage() {
    const val = localStorage.getItem('appLang');
    if (val !== undefined && val !== null) {
      return val;
    } else {
      return 'en-us';
    }
  }

  setTrackingAccess(tAccess: any) {
    localStorage.setItem('tAccess', JSON.stringify(tAccess));
  }

  getTrackingAccess() {
    const value = localStorage.getItem('tAccess');
    if (value !== undefined && value != null) {
      return value;
    }
    else {
      return null;
    }
  }

  setAzureAccess(azureAccess: any) {
    localStorage.setItem('azureAccess', JSON.stringify(azureAccess));
  }

  getAzureAccess(){
    const value = localStorage.getItem('azureAccess');
    if (value !== undefined && value != null) {
      return value;
    }
    else {
      return false;
    }
  }

  //S12-1202
   timeOut(){   
    setTimeout(() => {
      window.print();
      }, 100);
   }

}
