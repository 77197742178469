
    <div class="container">
    <div class="content has-text-centered">
        <div class="footer">
            <div class="container-fluid">
                <div [innerHtml]="FooterHtml">               
            </div>
        </div>
    </div>
    </div>

