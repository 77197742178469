import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalPopupService } from 'src/app/providers/modalPopup.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { ILogin, IBrandHF, ITrackingAccess, ISSOAccess } from 'src/app/auth/iapp-user';
import { AppError } from 'src/app/common/app-error';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserSessionService } from 'src/app/common/user-session.service';
import { TrackingService } from 'src/app/trackingservice/tracking.service';
import { AppConfigService } from 'src/app/app-config.service';
import { RefreshTokenService } from 'src/app/common/refreshToken.service';
import { MFAService } from 'src/app/mfaservice/mfa.service';
import { MFAResponseDetails, MFATokenResponse } from 'src/app/models/mfa-response.model';
import { UtilsService } from 'src/app/common/utils.service';
import { format } from 'date-fns';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  dialogRef: MatDialogRef<any>;
  isLoading: boolean = false;
  logoURL = "";
  externalUserName = "";
  externalToken = "";
  hdo: number = 0;
  captchaResponse: string | null;


  constructor(private formBuilder: FormBuilder, private modalPopupService: ModalPopupService,
    private router: Router, private authService: AuthService, public snackBar: MatSnackBar,
    private user: UserSessionService, private trackingService: TrackingService,
    private appConfigService: AppConfigService, private route: ActivatedRoute,
    private refresh: RefreshTokenService, private mfaService: MFAService,
    private utils: UtilsService) {

    //localStorage.clear();
  }

  get validate() { return this.loginForm.controls; }

  validPatternEmail: boolean = false;
  validEmail: boolean = false;
  validPassword: boolean = false;
  accessToken = "";
  userName = "";
  userEmail = "";
  spinner = false;
  errorReason = "";
  ssoType = "";
  validGoogleCaptcha = false;
  hideGoogleCaptcha = false;
  mfaEnabled = false;
  passwordEnforcement = 0;


  @HostListener('window:message', ['$event'])
  onMessage(e: MessageEvent) {

    console.log(e.origin);

    try {

      if (e.origin === this.appConfigService.ssoURL && e.data != null && e.data != undefined) {

        var dt = JSON.parse(e.data);
        var ssoType = "";

        if (JSON.parse(dt) != null && JSON.parse(dt) != undefined)
          ssoType = JSON.parse(dt).ssotype;

        if (ssoType.toLowerCase() == "azuread" || ssoType.toLowerCase() == "google") {

          localStorage.provider_access_token = e.data;
          console.log("data from rUI for SSO login");
          this.getTrackingAccessbyAzureAD();
        }
      }
      else {

        console.log("no data from rUI")
      }

    } catch (error) {

      if (e.origin === this.appConfigService.ssoURL && this.utils.ConvertBase64ToString(e.data)) {
        console.log("Received data from ui for authentication.");

        var loginDetails: any = this.utils.ConvertBase64ToString(e.data);
        loginDetails = JSON.parse(loginDetails);
        this.userEmail = loginDetails.JwtToken.emailaddress;
        this.authService.saveUserDetailsfromMFA(loginDetails);
        this.mfaSessionLogin();

      }
    }

  }



  ngOnInit() {

    this.logoURL = '../../assets/images/rmail-latest-logo.png';
    // this.appConfigService.logoUrl;

    this.buildLoginForm();

    this.route.queryParamMap.subscribe((params: any) => {

      if (params != null && params.params != null && params.params != undefined) {

        if (params.params.errorReason != null && params.params.errorReason != "" && params.params.errorReason != undefined) {
          this.errorReason = params.params.errorReason;
          this.openSnackBar(this.errorReason, "");
        }

        this.externalUserName = params.params.ru;
        this.externalToken = params.params.rk;
        this.hdo = params.params.hdo;

        var obj = {
          email: this.externalUserName,
          userToken: this.externalToken
        }

        if (obj.email != null && obj.email != undefined && obj.userToken != null && obj.userToken != undefined) {

          localStorage.clear();
          this.loginExternal(obj);
        }

      }
    });

    if (localStorage.getItem('provider_access_token')) {
      this.spinner = true;
      //this.azureAD = true;
      //console.log('login-provider_access_token');
      localStorage.setItem('isUnAuthorized', "N");
      this.getTrackingAccessbyAzureAD();
    }

    if (localStorage.getItem('user') === null || localStorage.getItem('user') === undefined) {
      this.refresh.stopTimer();
    }

  }

  buildLoginForm() {

    this.loginForm = this.formBuilder.group({
      email: new FormControl('', {
        validators: [Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")],
        //updateOn: 'blur'
      }),
      password: new FormControl('', {
        validators: [Validators.required,
          // Validators.minLength(7),
          // Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{7,}$")
        ],
        //updateOn: 'blur'
      }),
      rememberMe: new FormControl(null),
      captcha: new FormControl('')
    });
  }

  redirectToRegister() {

    window.location.href = this.appConfigService.rmailRegistrationLink;
  }

  onSubmit(form: FormGroup) {

    const fv = this.loginForm.value as ILogin;

    if (this.loginForm.invalid) {

      if (this.loginForm.controls.email.errors)
        this.validEmail = true;

      if (this.loginForm.controls.password.errors)
        this.validPassword = true;

      if (!this.mfaEnabled && this.captchaResponse)
        this.validGoogleCaptcha = true;

      return;
    }

    if ((!this.mfaEnabled || this.hideGoogleCaptcha) && (this.captchaResponse == undefined || this.captchaResponse == null && this.captchaResponse == "")) {
      this.validGoogleCaptcha = true;
      return;
    }

    var today = format(new Date(), 'MM/dd/yyyy HH:mm:ss');

    var trustDeviceDetailsInfo = {
      trustDeviceInfo: false,
      trustDeviceExpirationDate: new Date()
    }

    if (localStorage.getItem("trustDeviceDetails") != null
      && localStorage.getItem("trustDeviceDetails") != undefined) {

      var lclStrgtrustDeviceDetailsInfo = JSON.parse(localStorage.getItem("trustDeviceDetails") || '{}');

      trustDeviceDetailsInfo.trustDeviceInfo = lclStrgtrustDeviceDetailsInfo.trustDeviceInfo != null && lclStrgtrustDeviceDetailsInfo.trustDeviceInfo != undefined ? lclStrgtrustDeviceDetailsInfo.trustDeviceInfo : false;
      var expDate = lclStrgtrustDeviceDetailsInfo.trustDeviceExpirationDate != null && lclStrgtrustDeviceDetailsInfo.trustDeviceExpirationDate != undefined ? new Date(lclStrgtrustDeviceDetailsInfo.trustDeviceExpirationDate) : format(new Date(), 'MM/dd/yyyy HH:mm:ss');

      if (expDate >= today)
        trustDeviceDetailsInfo.trustDeviceInfo = false;

    }

    this.validEmail = false;
    this.validPassword = false;
    this.validGoogleCaptcha = false;
    this.isLoading = true;

    //if (this.mfaEnabled && !trustDeviceDetailsInfo.trustDeviceInfo) {

      //this.generateMFAToken(fv);

   // }
    //else {

      var googleRes = this.authService.googleCaptchaTokenVerification(this.captchaResponse)
        .subscribe(resp => {
          //  this.captchaResponse = null;   //S12-1169
          // setTimeout(() => {
          //   grecaptcha.reset();
          // }, 100);

          if (resp instanceof AppError) {
            var err = resp;
            this.isLoading = false;
            this.captchaResponse = null;
            setTimeout(() => {
              grecaptcha.reset();
            }, 100);
          }
          else {

            this.authService.logout();

            var res = this.authService.login(fv)
              .subscribe(resp => {

                if (resp instanceof AppError) {
                  this.captchaResponse = null;
                  setTimeout(() => {
                    grecaptcha.reset();
                  }, 100);
                  //const msg: string = resp.Message;

                  // if (msg.includes('name or password')) {            
                  //   this.openSnackBar('Name or Password is incorrect.', '');
                  // } 

                  // if (msg.includes('not confirmed')) {
                  //   this.openSnackBar('User email not confirmed', '');
                  // }

                  // if(msg.includes('pending activation')){
                  //   this.openSnackBar('User pending activation.', '');
                  // }
                  if (resp.originalError != null && resp.originalError.error != null
                    && resp.originalError.error.resultContent != null && resp.originalError.error.resultContent.errors.length > 0) {

                    const msg: string = resp.originalError.error.resultContent.errors[0].message;
                    this.openSnackBar(msg, '');
                  }

                  if (resp.originalError != null && resp.originalError.error != null && resp.originalError.error.message != null && resp.originalError.error.message.messageCode == "RCAP-1016") {

                    const msg: string = "Your IT administrator has restricted direct login to this application for your domain. Please use your Single Sign-On to login to the application.";
                    this.openSnackBar(msg, '');
                  }

                  if (resp.originalError.status == 500) {
                    this.openSnackBar(resp.originalError.message, '');
                  }

                  this.isLoading = false;

                }
                else {

                  this.refresh.startTimer();
                  //this.getBrandInfo();
                  //this.getTrackingAccess();
                  this.router.navigate(['/trackingesign']);
                  //this.ssoEnableCheck(fv.email,true);
                  return;
                }
                this.captchaResponse = null;
                setTimeout(() => {
                  grecaptcha.reset();
                }, 100);
              });

          }

        });

    //}

  }

  loginExternal(loginData: any) {

    this.authService.loginExternal(loginData);

    this.authService.getValidateToken().subscribe((resp:any)=>{
      
      if (resp instanceof AppError) {
        var err = resp;
        if (err.originalError.error.message != null
          && err.originalError.error.message.messageCode == 'RCAP-1004') {

          this.openSnackBar(err.originalError.error.message.message, "");
          this.authService.logout();

          if (window.origin == this.appConfigService.rsuitUrlLink)
            window.parent.postMessage({ event: 'LogOut', message: 'TrackEsign' }, this.appConfigService.rsuitUrlLink);

          this.router.navigate(['/login']);
          return;
        }
      }
      else{

        var user = this.user.getUser();
        user.loginToken[".expires"] = resp.resultContent.expires;
        user.loginToken[".issued"] = resp.resultContent.issued;
        user.expires_in = resp.resultContent.expires_in;

        this.user.setUser(user);
        this.router.navigate(['/trackingesign']);
      }

    });

  }

  getBrandInfo() {

    const frm = this.loginForm.value as ILogin;
    const email = frm != null && frm != undefined && frm.email != null && frm.email != "" ? frm.email : this.userEmail;
   
    var result = this.authService.getBrand(email)
      .subscribe(resp => {
        if (!(resp instanceof AppError)) {
          
          const response = resp as IBrandHF;
          
          const result = response.resultContent;
          const brand = {
            headerHtml: result.headerHtml,
            footerHtml: result.footerHtml
          };
          this.user.setBrand(brand);
        }
      });
  }

  getTrackingAccess() {

    var result = this.trackingService.getTrackingAccess().subscribe(resp => {
      this.isLoading = false;
      if (!(resp instanceof AppError)) {
        
        var response = resp as ITrackingAccess;
        
        var result = response.resultContent;
        result.webTrackingAccess = result.webTrackingAccess != null && result.webTrackingAccess != undefined ? result.webTrackingAccess : false;
        this.user.setTrackingAccess(result);

        this.router.navigate(['/trackingesign']);
      }

    });
  }

  forgetPassword() {

    this.dialogRef = this.modalPopupService.openPopup<ForgotPasswordComponent>(
      ForgotPasswordComponent,
      null
    );
    this.dialogRef.afterClosed().subscribe(result => {

    });

  }

  getTrackingAccessbyAzureAD() {

    var externalData = JSON.parse(localStorage.getItem("provider_access_token") || "{}");

    externalData = JSON.parse(externalData);
    if (externalData != null && externalData != "" && externalData != undefined) {

      this.accessToken = externalData.accesstoken;
      this.userName = externalData.username;
      this.userEmail = externalData.useremail;
      this.ssoType = externalData.ssotype;

      var obj = {
        provider_Access_Token: this.accessToken,
        providerUserName: this.userName,
        providerUserEmail: this.userEmail,
        ssoType: this.ssoType
      }

      this.authService.getadportal(obj).subscribe(resp => {

        if (!(resp instanceof AppError)) {
          //this.refresh.startTimer();
          this.router.navigate(['/trackingesign']);
          //this.getBrandInfo();
          //this.getTrackingAccess();
        }
        else {

          if (resp.originalError.error.message != null) {
            var errMessage = resp.originalError.error.message.message;
            this.openSnackBar(errMessage, "");
          }

          if (resp.originalError.error.resultContent != null &&
            resp.originalError.error.resultContent.errors != null) {

            var errList = resp.originalError.error.resultContent.errors;

            errList.forEach((item: any) => {
              var errCode = item.code;
              var errMsg = item.message;
              this.openSnackBar(item.message, "");
            });

          }

          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);

        }
      });
    }
  }

  adportal() {

    this.refresh.stopTimer();
    this.authService.logout();

    var isUnAuthorized = localStorage.getItem('isUnAuthorized');
    isUnAuthorized = isUnAuthorized != "" && isUnAuthorized != null ? isUnAuthorized : "N";
    var callBackURL = location.href.split('?');
    var loginURL = callBackURL[0];

    if (this.errorReason != null && this.errorReason != "")
      location.href = (this.appConfigService.ssoURL + "//rpost-authentication?RpostClientId=" + this.appConfigService.clientId + "&CallBackUrl=" + loginURL + "&LoginUrl=" + loginURL + "&IsUnAuthorized=" + isUnAuthorized + "&ShowLoginPopup=" + "Y");
    else
      location.href = (this.appConfigService.ssoURL + "//rpost-authentication?RpostClientId=" + this.appConfigService.clientId + "&CallBackUrl=" + loginURL + "&LoginUrl=" + loginURL + "&IsUnAuthorized=" + isUnAuthorized);
  }

  onEmailBlur(event: any) {

    if (this.loginForm.controls.email.errors && this.loginForm.controls.email.value != '')
      this.validPatternEmail = true;
    else {
      this.validPatternEmail = false;
      //this.mfaLoginCheck(this.loginForm.controls.email.value);
    }

    //this.ssoEnableCheck(event.target.value, false);

  }

  mfaLoginCheck(emailAddress: string) {

    var trustDeviceInfo = false;
    this.isLoading = true;

    if (localStorage.getItem("trustDeviceDetails") != null && localStorage.getItem("trustDeviceDetails") != undefined) {
      var lclStrgDeviceInfo = JSON.parse(localStorage.getItem("trustDeviceDetails") || '{}');
      trustDeviceInfo = lclStrgDeviceInfo.trustDeviceInfo;
    }

    var obj = {
      emailAddress: emailAddress,
      browser_Device: "chrome-desktop",
      infoExist: trustDeviceInfo
    }

    this.mfaService.getMFALoginSetting(obj).subscribe(resp => {

      if (resp != null) {

      if (resp instanceof AppError) {
        var err = resp;

        if (resp.originalError.error.message != null) {
          var errMessage = resp.originalError.error.message.message;
          this.openSnackBar(errMessage, "");
        }

        if (resp.originalError.error.resultContent != null &&
          resp.originalError.error.resultContent.errors != null) {

          var errList = resp.originalError.error.resultContent.errors;

          errList.forEach((item: any) => {
            var errCode = item.code;
            var errMsg = item.message;
            this.openSnackBar(item.message, "");
          });

        }
      }
      else {

        var respData = resp as MFAResponseDetails;

        if (respData.resultContent.mfaEnabled) {
          this.mfaEnabled = respData.resultContent.mfaEnabled;

          if (respData.resultContent.mfaTrustedDevice || trustDeviceInfo)
            this.hideGoogleCaptcha = true;
          else
            this.hideGoogleCaptcha = false;

        }
        else {
          this.hideGoogleCaptcha = true;
        }

        if (respData.resultContent.passwordChange != 0) {
          this.passwordEnforcement = respData.resultContent.passwordChange;
        }
        else {
          this.passwordEnforcement = respData.resultContent.passwordChange;
        }

      }

      }
      else{
        this.hideGoogleCaptcha = true;
      }
      
      this.isLoading = false;

    });

  }

  generateMFAToken(logIn: ILogin) {

    var obj = {
      emailAddress: logIn.email,
      userPassword: logIn.password
    }

    this.mfaService.generateMFAToken(obj).subscribe(resp => {

      this.isLoading = false;

      if (resp instanceof AppError) {

        if (resp.originalError.error.message != null) {
          var errMessage = resp.originalError.error.message.message;
          this.openSnackBar(errMessage, "");
        }

        if (resp.originalError.error.resultContent != null &&
          resp.originalError.error.resultContent.errors != null) {

          var errList = resp.originalError.error.resultContent.errors;

          errList.forEach((item: any) => {
            var errCode = item.code;
            var errMsg = item.message;
            this.openSnackBar(item.message, "");
          });

        }

      }
      else {

        var resData = resp as MFATokenResponse;
        var callBackURL = location.href.split('?');
        var loginURL = callBackURL[0];

        var mfaQS = "SourceClientId=" + this.appConfigService.clientId + "&MFAToken=" + resData.resultContent.mfA_Token + "&CallBackUrl=" + loginURL + "&LoginUrl=" + loginURL + "&MFAEEnabled=" + this.mfaEnabled + "&Lang=" + resData.resultContent.userLanguage;
        var strtob64 = this.utils.ConvertStringToBase64(mfaQS.toString());
        location.href = this.appConfigService.ssoURL + "/email-authentication?qs=" + strtob64;
      }

    });

  }

  mfaSessionLogin() {

    this.authService.getValidateToken().subscribe((resp: any) => {

      if (resp instanceof AppError) {

        if (resp.originalError.error.message != null) {
          var errMessage = resp.originalError.error.message.message;
          this.openSnackBar(errMessage, "");
        }

        if (resp.originalError.error.resultContent != null &&
          resp.originalError.error.resultContent.errors != null) {

          var errList = resp.originalError.error.resultContent.errors;

          errList.forEach((item: any) => {
            var errCode = item.code;
            var errMsg = item.message;
            this.openSnackBar(item.message, "");
          });

        }

      }
      else {

        this.refresh.startTimer();
        this.router.navigate(['/trackingesign']);
        //this.getBrandInfo();
        //this.getTrackingAccess();
        
      }

    });

  }


  ssoEnableCheck(value: any, isLogin: any) {

    this.authService.ssoEnabledValidationCheck(value).subscribe(resp => {

      var result = resp as ISSOAccess;
      if (resp instanceof AppError) {
        resp.Message;
      }
      else {

        if (result.statusCode == 200 && result.resultContent.ssoEnabled == true) {
          var msg = "Your IT administrator has restricted direct login to this application for your domain. Please use your Single Sign-On to login to the application.";
          this.openSnackBar(msg, "");

          if (isLogin) {
            this.authService.logout();
            this.router.navigate(['/login']);
          }
        }
        else if (result.statusCode == 200 && result.resultContent.ssoEnabled == false) {
          if (isLogin) {
            this.refresh.startTimer();
            //this.getBrandInfo();
            //this.getTrackingAccess();
            this.router.navigate(['/trackingesign']);
          }
        }
      }

    });

  }

  resolved(event: any) {

    if (event === null) {
      setTimeout(() => {
        grecaptcha.reset();
      }, 100);
    }
  }

  errored(event: any) {

    this.openSnackBar("Retry recaptcha.", "");

    setTimeout(() => {
      grecaptcha.reset();
    }, 100);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

}

