

export class SearchFilter {

    SenderAddress: string;
    ExpirationDays: number | null;
    Subject: string;
    MessageId: string;
    EsignState: number | null;
    DateFrom: Date | null;
    DateTo: Date | null
    MessageType: number | null
    RecipientAddress: string;
    MessageStatus: string;
    IsCompany: boolean;
    PageNumber: number;
    PageSize: number;
    Column:string;
    Direction:string;
}

export interface ImessageIds {
    status: string;
    statusCode: number;
    statusText: string;
    success: boolean;
    resultContent: messageIDValue[]
}

export class messageIDValue {
    value: string;
}

export interface IsenderRecipient {
    status: string;
    statusCode: number;
    statusText: string;
    success: boolean;
    resultContent: senderRecipientValue[]
}

export class senderRecipientValue {
    value: string;
}