import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NoopAnimationsModule, BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoginComponent } from './components/login/login.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ModalPopupService } from '../app/providers/modalPopup.service';
import { AuthGuardService } from './auth/auth-guard.service';
import { AuthService } from './auth/auth.service';
import { UserSessionService } from './common/user-session.service';
import { AppConfigService } from './app-config.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth/auth-interceptor';
import { DataApiService } from './common/data-api.service';

import { TrackingEsignComponent } from './components/tracking-esign/tracking-esign.component';
import { MaterialModule } from './material.module';
import { MessageDetails } from './components/message-details/message-details.component';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { TrackingService } from './trackingservice/tracking.service';
import { CancelTransactionComponent } from './components/cancel-transaction/cancel-transaction.component';
import { LoginAuthGuard } from './auth/login.auth.guard.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ResetPassword } from './components/reset-password/reset-password.component';
import { RefreshTokenService } from './common/refreshToken.service';
import { RecaptchaModule, RecaptchaFormsModule, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { MFAService } from './mfaservice/mfa.service';
import { DataMFAApiService } from './common/data-mfa.service';
import { UtilsService } from './common/utils.service';
import { MouseEventsService } from './common/mouseevents.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    UserRegistrationComponent,
    ForgotPasswordComponent,
    TrackingEsignComponent,
    MessageDetails,
    SearchFilterComponent,
    CancelTransactionComponent,
    ResetPassword
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    NgxMatSelectSearchModule,
    RecaptchaModule,
    RecaptchaFormsModule, // if you need forms support    
  ],
  providers: [AppConfigService, UserSessionService, AuthService, AuthGuardService, LoginAuthGuard, ModalPopupService, DataApiService, TrackingService, RefreshTokenService, DataMFAApiService, MFAService, UtilsService, MouseEventsService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.googleCaptchaSiteKey } as RecaptchaSettings,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

}
