import { AfterViewChecked, Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/app-config.service';
import { UserSessionService } from 'src/app/common/user-session.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements AfterViewChecked, OnInit {

  constructor(private sanitizer: DomSanitizer, private user: UserSessionService,
    private config: AppConfigService) { }

    public displayUser: string = "";
    hideElement = false;
    FooterHtml: SafeHtml;
    appLangs = [];

  ngOnInit() {

    // setTimeout(() => {
      
    //   const brand = this.user.getBrand();
    // if (brand !== null) {
    //   this.FooterHtml = this.sanitizer.sanitize(SecurityContext.HTML, brand.footerHtml) || {};
    // } else {
    //   this.FooterHtml = '';
    // }

    // }, 1000);

  }
//(TM)
  ngAfterViewChecked(){

    const brand = this.user.getBrand();
    if (brand !== null) {
      brand.footerHtml=brand.footerHtml.replace(/®/g, '<sup>®</sup>').replace(/™/g, '<sup>™</sup>');
      this.FooterHtml = this.sanitizer.sanitize(SecurityContext.HTML, brand.footerHtml) || {};
      
    } else {
      this.FooterHtml = '';
    }

  }


}
