<app-header *ngIf="!externallogin.isExternalLogin"></app-header>
<!-- #masthead -->
<!-- <div class="content-wrap"> -->
<div (mousemove)="onMouseMove($event)" (click)="onHandleClick($event)">
<div class="inner-header">
    <div class="pull-left">
        <h5>Message Details</h5>
    </div>
    <div class="pull-right mr-16">
        <ul>
            <li><i (click)="print()" class="fa fa-print btn-nav" (click)="print()" title="Print"></i></li>
            <li>
                <div class="dropdown btn-nav" (click)="toggleDropdown()" [class.open]="showDropdown">
                    <i class="fa fa-user ml-7 btn-nav dropdown-toggle"></i>
                    <div class="dropdown-menu top-triangle" *ngIf="showDropdown">
                        <div class="user-dropdown">
                            <i class="fa fa-user ml-6"></i>
                            <span class="username">{{messageDetail.senderAddress}}</span>
                        </div>
                        <div class="signout-dropdown" *ngIf="!externallogin.isExternalLogin" (click)="logout()">
                            <i class="fa fa-sign-out ml-7"></i>
                            <span class="signout-ml-3">Sign Out</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
<div class="content-wrap my-ms-1">
    <div class="page-container mh-80">
        <div class="mb-30">
            <div class="container" ng-init="loadMessage()">
                <!-- <div ng-show="errMsg" class="alert alert-danger col-sm-12 invisible" id="authErr">
                {{errMsg}}
            </div> -->
                <div ng-hide="errMsg" class="message-id-list">
                    <form role="form" class="form-horizontal" id="frmUser" name="frmUser" novalidate>
                        <div class="breadcrums">
                            <ul>
                                <li><a href="javascript:void(0)" (click)="loadTracking()">Tracking</a></li>
                                <li> Message Details</li>
                                <button mat-flat-button type="button" (click)="cancelTranscation()"
                                    [disabled]="boolCancelTransaction" class="btn-right btn-close btn-customize"> Cancel
                                    Transaction</button>
                            </ul>
                        </div>
                        <table class="message-details fl">
                            <tr class="noerrTag" id="pMMessageIdReq">
                                <td>
                                    <label>Message ID:</label>
                                    <span class="pt-10">{{messageDetail.messageId}}</span>
                                </td>
                                <td>
                                    <label>Message Date:</label>
                                    <span class="pt-10">{{messageDetail.dateSent}}</span>
                                </td>
                            </tr>

                            <tr class="noerrTag" id="pMDateReq">
                                <td>
                                    <label>Sender:</label>
                                    <span class="pt-10">{{messageDetail.senderAddress}}</span>
                                </td>
                                <td> <label>Service Type:</label>
                                    <span class="pt-10">{{messageDetail.serviceType}}</span>
                                </td>
                            </tr>
                            <tr class="noerrTag" id="pMSubjectReq">
                                <td colspan="1">
                                    <div style="width: 150px" class="subject fl">Sign in Sequence:</div>
                                    <div class="subject-details fl">{{messageDetail.esignSequential}}</div>
                                </td>
                                <td colspan="1">
                                    <div style="width: 150px" class="subject fl">Offer Due Date:</div>
                                    <div class="subject-details fl">{{messageDetail.esignExpirationDate}}</div>
                                </td>
                            </tr>
                            <tr class="noerrTag" id="pMSubjectReq">
                                <td colspan="2">
                                    <div style="width: 150px" class="subject fl">Subject:</div>
                                    <div class="subject-details fl">
                                        <span>
                                            {{messageDetail.subject}}
                                            <!-- Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. -->
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr class="noerrTag" id="pMSubjectReq">
                                <td colspan="2">
                                    <div style="width: 150px" class="recipients fl">Recipients:</div>
                                    <div class="recipients-list">

                                        <span *ngFor="let item of RecipientAddress">
                                            <i class="fa fa-user" aria-hidden="true"></i>{{item}};
                                        </span>

                                    </div>
                                </td>
                            </tr>

                        </table>


                        <div>
                            <!-- <div class="delivery-status" >
                    <h4>DELIVERY STATUS : Delivered</h4>
                    <div id="msgRecipients">
                        <ul>
   <li *ngFor="let recipient of receipientDetails">
                                <span>{{recipient.orderNum}}. {{recipient.Address}}</span>
                                <div class="status">
                                    <label>Esign Status : </label>
                                    <div>
                                        {{recipient.statusText}}
                                        <span ng-show="recipient.actionDate">at {{recipient.actionDate |
                                            date:'yyyy-MM-dd
                                            h:mm:ss a'}} (UTC)</span>
                                        <span ng-show="recipient.secondStatus">{{recipient.secondStatus}}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> -->
                            <div class="delivery-status progress-bar">
                                <h5 class="status-details">
                                    <span class="status-details1">Status : {{strStatus}}</span>
                                </h5>

                                <div class="progress-bar-circles">
                                    <span class="circles" *ngIf="!this.boolDeclineorCancel"
                                        [class.active]="steps?.Status[0]"></span>
                                    <span class="circles" *ngIf="this.boolDeclineorCancel||this.boolDeliveryFailure"
                                        [class.orangeActive]="steps?.Status[0]"></span>
                                    <span class="bar" *ngIf="!this.boolDeclineorCancel"
                                        [class.boldLine]="steps?.Status[1]||steps?.Status[2]"></span>
                                    <span class="bar" *ngIf="this.boolDeclineorCancel"
                                        [class.orangeLine]="steps?.Status[1]||steps?.Status[2]"></span>
                                    <span class="circles" *ngIf="!this.boolDeclineorCancel"
                                        [class.active]="steps?.Status[1]||steps?.Status[2]"></span>

                                    <span class="circles" *ngIf="this.boolDeclineorCancel"
                                        [class.orangeActive]="steps?.Status[1]||steps?.Status[2]"></span>
                                    <span *ngIf="!this.boolDeclineorCancel" class="bar"
                                        [class.boldLine]="steps?.Status[2] "></span>
                                    <span *ngIf="this.boolDeclineorCancel" class="bar"
                                        [class.orangeLine]="steps?.Status[2] "></span>
                                    <span class="circles" *ngIf="!this.boolDeclineorCancel"
                                        [class.active]="steps?.Status[2]"></span>
                                    <span class="circles" *ngIf="this.boolDeclineorCancel"
                                        [class.orangeActive]="steps?.Status[2]"></span>
                                </div>

                                <div class="status-bar">
                                    <!-- Signature Submit status -->
                                    <div class="fl mt-10 w-40p h-60 ew-33p">
                                        <span class="circle-desc" [class.circle-desc-active]="steps?.Status[0]">
                                            {{steps?.statuesName[0]}}</span>
                                        <span class="circle-desc-date" [class.circle-desc-active]="steps?.Status[0]">
                                            {{steps?.ActionTime[0]}}</span>
                                    </div>

                                    <!-- pending status -->
                                    <div class="fl mt-10 w-40p h-60 ">
                                        <span class="circle-desc" *ngIf="steps?.Status[1]"
                                            [class.circle-desc-active]="steps?.Status[1]">{{steps?.statuesName[1]}}</span>
                                        <span class="circle-desc-date" *ngIf="steps?.Status[1]"
                                            [class.circle-desc-active]="steps?.Status[1]">{{steps?.ActionTime[1]}}</span>
                                    </div>

                                    <!-- Deliver status -->
                                    <div class="fl mt-10 w-20p h-60 ew-33p">
                                        <span class="circle-desc" *ngIf="steps?.Status[2]"
                                            [class.circle-desc-active]="steps?.Status[2]">{{steps?.statuesName[2]}}</span>
                                        <span class="circle-desc-date" *ngIf="steps?.Status[2]"
                                            [class.circle-desc-active]="steps?.Status[2]">{{steps?.ActionTime[2]}}</span>
                                    </div>
                                </div>


                                <div>
                                    <span class="invisiblebar"></span>
                                    <span class="invisiblebar"></span>
                                    <span class="invisiblebar"></span>
                                </div>
                            </div>
                            <!-- Reason Message -->
                            <div class="status-bar1">
                                <!-- <div class="fl mt-15 w-20p h-60"></div> -->
                                <div class="fl mt-15 w-56p h-60 disp-none"></div>
                                <div class="fl mt-15 w-44p h-60">
                                    <!-- <span class="circle-desc" 
                        *ngIf="strStatus== 'Cancelled'" [class.circle-desc-active]="steps?.Status[2]" ></span> -->
                                    <span class="circle-desc-date" *ngIf="strStatus== 'Cancelled'"
                                        [class.circle-desc-active]="steps?.Status[2]">
                                        <span>Reason:</span>
                                        <span> {{messageDetail.cancelReason}}</span></span>
                                </div>
                            </div>
                            <!-- <div class="delivery-status" >
                    <h5 style="margin-bottom: 50px; font-size: 20px;">Status : {{strStatus}}</h5>
                                       <div>
                        <span class="circles" *ngIf="!this.boolDeclineorCancel" [class.active]="steps?.Status[0]"></span>
                        <span class="circles" *ngIf="this.boolDeclineorCancel" [class.orangeActive]="steps?.Status[0]"></span>
                        <span class="bar" *ngIf="!this.boolDeclineorCancel" [class.boldLine]="steps?.Status[1]||steps?.Status[2]" ></span>
                        <span class="bar" *ngIf="this.boolDeclineorCancel" [class.orangeLine]="steps?.Status[1]||steps?.Status[2]" ></span>
                       
                        <span class="circles" *ngIf="!this.boolDeclineorCancel" [class.active]="steps?.Status[1]||steps?.Status[2]"></span>
                        <span class="circles" *ngIf="this.boolDeclineorCancel" [class.orangeActive]="steps?.Status[1]||steps?.Status[2]"></span>
                      
                   <span *ngIf="!this.boolDeclineorCancel"  class="bar" [class.boldLine]="steps?.Status[2] ">
                    </span> 
                    <span *ngIf="this.boolDeclineorCancel"  class="bar" [class.orangeLine]="steps?.Status[2] ">
                    </span> 
                    <span class="circles" *ngIf="!this.boolDeclineorCancel" [class.active]="steps?.Status[2]"></span>
                    <span class="circles" *ngIf="this.boolDeclineorCancel" [class.orangeActive]="steps?.Status[2]" ></span>
                    </div><div>
                        <span class="circle-desc" [class.circle-desc-active]="steps?.Status[0]"> {{steps?.statuesName[0]}}</span>
                        <span class="invisiblebar" ></span>
                        <span >   <span class="circle-desc" *ngIf="steps?.Status[1]"  [class.circle-desc-active]="steps?.Status[1]">{{steps?.statuesName[1]}}</span>
                     </span>   <span class="invisiblebar" ></span>
                        <span class="circle-desc" *ngIf="steps?.Status[2]" [class.circle-desc-active]="steps?.Status[2]" >{{steps?.statuesName[2]}}</span>
                      
                    </div>
                    <div>
                        <span class="circle-desc" [class.circle-desc-active]="steps?.Status[0]"> {{steps?.ActionTime[0]}}</span>
                        <span class="invisiblebar" ></span>
                        <span > <span class="circle-desc" *ngIf="steps?.Status[1]"  [class.circle-desc-active]="steps?.Status[1]">{{steps?.ActionTime[1]}}</span>
                      </span>  <span class="invisiblebar" ></span>
                        <span class="circle-desc" *ngIf="steps?.Status[2]" [class.circle-desc-active]="steps?.Status[2]" >{{steps?.ActionTime[2]}}</span>
                      
                    </div>
                     
                </div> -->
                        </div>

                    </form>
                </div>
            </div>

            <div class="table-striped subject-grid">
                <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
                    <ng-container *ngFor="let column of columnsToDisplay; let i=index" [matColumnDef]="column.field">
                        <th mat-header-cell *matHeaderCellDef>
                            <span class="font-bold"> {{column.field!='action'?column.header:''}} </span>
                        </th>
                        <td mat-cell *matCellDef="let element"> {{element[column.field]}}

                            <button *ngIf="column.field=='action'&&!element.expanded" mat-icon-button
                                [class.expanded]="element.expanded" (click)="toggleRow(element)">
                                <!-- <i class="fa fa-chevron-down"></i> -->
                                <mat-icon>keyboard_arrow_down</mat-icon>
                            </button>
                            <button *ngIf="column.field=='action'&&element.expanded" mat-icon-button
                                [class.expanded]="element.expanded" (click)="toggleRow(element)">
                                <!-- <i class="fa fa-chevron-up"></i> -->
                                <mat-icon>keyboard_arrow_up</mat-icon>
                            </button>

                            <button mat-flat-button
                                *ngIf="column.field=='senderReminder' && !boolCancelTransaction
                       &&(element.eSignStatus=='Sent For Signature' || element.eSignStatus=='Signature In Progress' || element.eSignStatus=='Contract Viewed')"
                                (click)="sendReminder(element)" class="btn-sendReminder btn-customize"
                                [disabled]="element.Enabled">
                                Send Reminder
                            </button>
                        </td>

                    </ng-container>

                    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                    <ng-container matColumnDef="expandedDetail">
                        <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length"
                            class="rp-border-grey">
                            <div class="example-element-detail hed-msg-dils-sty" *ngIf="3" [@detailExpand]="isExpanded(element)">
                                <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                                    <table #innerTables id="innertable" class="table table-striped">
                                        <thead class="table-header table-row">
                                            <tr>
                                                <th class="font-bold" mat-header-cell *ngFor="let column of innerDisplayedColumns; let c = index">
                                                    {{column.header}} </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let childrow of element.status; let inde = index">
                                                <ng-container *ngFor="let innerColumn of innerDisplayedColumns">
                                                    <td mat-cell
                                                        *ngIf="innerColumn.field !== 'statusUpdated'; else dateTemplate">
                                                        {{childrow[innerColumn.field]}}
                                                    </td>
                                                    <ng-template #dateTemplate>
                                                        <div class="st-up">
                                                        <div class="st-up-1" *ngIf="isArray(childrow[innerColumn.field])">
                                                            <div
                                                                *ngFor="let gridItem of childrow[innerColumn.field]; let last = last">
                                                                {{ gridItem }}
                                                                <ng-container *ngIf="!last"><br><br></ng-container>
                                                            </div>
                                                        </div>
                                                        <div class="st-up-2" *ngIf="!isArray(childrow[innerColumn.field])">
                                                            {{ childrow[innerColumn.field] }}
                                                        </div>
                                                    </div>
                                                    </ng-template>

                                                </ng-container> <!-- This was corrected from <td> to </td> -->
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>
                    <tr mat-row *matRowDef="let element; columns: headers;" [class.example-element-row]="3"
                        [class.example-expanded-row]="expandedElement === element">
                    </tr>
                    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

                </table>
            </div>
        </div>
    </div>
</div>
</div>

<app-footer *ngIf="!externallogin.isExternalLogin"></app-footer>