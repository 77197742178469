// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  rpostTermUrl: 'https://www.rmail.com/legal-notices-2/general-terms-conditions/',
  //clientId: '70A2E9CA-2D73-46C4-88F4-56A00A4AEA43',
  clientId: 'B21DFC03-620B-4E9E-9C90-9EA03AACFA73',
  verson: 'v2.0.5.0026',
  
  // DEV
  //googleCaptchaSiteKey: '6Lcfw0kjAAAAAPEhhEyxSGM9lFGcTIKrFrFD7QzW',
  //googleCaptchaSecretKey: '6Lcfw0kjAAAAADYrV69mrKs0o4qxZmvz8a7ilTkO',
  // baseUrl: 'http://localhost:8084',
  // authURL: 'http://localhost:8081',
  // resetPwdUrl:'http://tracking.qa.rpost.net/password/',
  // tokenURL: 'http://localhost:8090',
  // logoUrl: 'http://open.qa.rpost.net/open/brand/e01LwlYpx8xO7NvUkKBpvYlJ5WUEuGVmzH2edEMqxevPDjBguKVJXh86erZ7RTO9XfAj_XsKy3pvfB2y510zPA==',
  // ssoURL: '',
  // ssoEnabled: "http://rportalapi.qa.rpost.net",
  //rmailRegistrationLink: "http://rmail.qa.rpost.net/account",

  // QA
  //googleCaptchaSiteKey: '6LesQRcjAAAAAFK-wbyU-IE1CvGdMRfqdaAEK5yn',
  //googleCaptchaSecretKey: '6LesQRcjAAAAAH0KKoxr4Fp3kyWdqiMswd6FsMD4',
  // baseUrl: 'http://webapi2.qa.rpost.net',
  // authURL: 'http://webapi.qa.rpost.net',
  // resetPwdUrl:'http://tracking.qa.rpost.net/password/',
  // tokenURL: 'http://auth.qa.rpost.net',
  // logoUrl: 'http://open.qa.rpost.net/open/brand/e01LwlYpx8xO7NvUkKBpvYlJ5WUEuGVmzH2edEMqxevPDjBguKVJXh86erZ7RTO9XfAj_XsKy3pvfB2y510zPA==',
  // ssoURL: '',
  // ssoEnabled: "http://rportalapi.qa.rpost.net",
  // rmailRegistrationLink: "http://rmail.qa.rpost.net/account",
  //RSuiteUrl: "http://rportalsuite2.qa.rpost.net/",

  // QA Prime
  //googleCaptchaSiteKey: '6LesQRcjAAAAAFK-wbyU-IE1CvGdMRfqdaAEK5yn',
  //googleCaptchaSecretKey: '6LesQRcjAAAAAH0KKoxr4Fp3kyWdqiMswd6FsMD4',
  // baseUrl: 'http://webapi2.pr.qa.rpost.net',
  // authURL: 'http://webapi.pr.qa.rpost.net',
  // resetPwdUrl:'http://tracking.pr.qa.rpost.net/password/',
  // tokenURL: 'http://auth.qa.rpost.net',
  // logoUrl: 'http://open.pr.qa.rpost.net/open/brand/e01LwlYpx8xO7NvUkKBpvYlJ5WUEuGVmzH2edEMqxevPDjBguKVJXh86erZ7RTO9XfAj_XsKy3pvfB2y510zPA==',
  // ssoURL: '',
  // ssoEnabled: "http://rportalapi.qa.rpost.net",
  // rmailRegistrationLink: "http://rmail.qa.rpost.net/account",
  // RSuiteUrl: "http://rportalsuite2.qa.rpost.net/",
  

  // stag
  //googleCaptchaSiteKey: '6LdSQxcjAAAAAHY_7jDeTozX4SSN2MwIkQfJ-xIa',
  //googleCaptchaSecretKey: '6LdSQxcjAAAAAJ72gDknG2eraPGiO0HtIOKrYOPJ',
  //  baseUrl: 'https://iapi.s1.rpost.info',
  //  authURL: 'https://webapi.s1.rpost.info',
  //  resetPwdUrl: 'https://tracking2.rpost.com/password/',
  //  logoUrl: 'https://assets.r1.rpost.net/assets/portal/tplogo.gif',
  //  tokenURL: 'https://auth.s1.rpost.info',
  //  ssoURL: 'https://rcap.s1.rpost.info',
  //  ssoEnabled: "https://rportalapi.s1.rpost.info",
  // rmailRegistrationLink: "https://rmail.s1.rpost.info/account",
  // RSuiteUrl: "https://rportalsuite.s1.rpost.info/",

  //prod
  // googleCaptchaSiteKey: '6LcwPRcjAAAAAHxlo4N06fkinOxqC44rgNY_Jbql',
  // googleCaptchaSecretKey: '6LcwPRcjAAAAAPWy4ips9HQM4Fx0AgmgnYiG9f5k',
  // baseUrl: 'https://webapi5.r1.rpost.net',
  // authURL: 'https://webapi.r1.rpost.net',
  //   resetPwdUrl:'https://tracking2.rpost.com/password/',
  //  logoUrl: 'https://assets.r1.rpost.net/assets/portal/tplogo.gif',
  // tokenURL: 'https://auth.r1.rpost.net',
  // ssoURL: 'https://rcap.r1.rpost.net',
  // ssoEnabled: "https://rportalapi.r1.rpost.net",
  // rmailRegistrationLink: "https://app.rmail.com/account",
  // RSuiteUrl: "https://rsuite.r1.rpost.net/",

  googleCaptchaSiteKey: '6Le_UMAlAAAAAKi-DOEw7XnESSzVd_CkrJZpZudM',
  googleCaptchaSecretKey: '6Le_UMAlAAAAAFuTzAdZ0VL_bVLIbgZqtTEbHg2V',
  baseUrl: 'https://webapi5.r2.rpost.net',
  authURL: 'https://webapi.r2.rpost.net',
  resetPwdUrl:'https://tracking.r2.rpost.net/password/',
  logoUrl: 'https://assets.r2.rpost.net/assets/portal/tplogo.gif',
  tokenURL: 'https://auth.r2.rpost.net',
  ssoURL: 'https://rcap.r2.rpost.net',
  ssoEnabled: "https://rportalapi.r2.rpost.net",
  rmailRegistrationLink: "",
  RSuiteUrl: "",
  
  //Prod Prime

  // googleCaptchaSiteKey: '6LfVCzsqAAAAACw1aUcvCf2asFQllEBZrhgFUe1x',
  // googleCaptchaSecretKey: '6LfVCzsqAAAAAAolmU7tL3S_nKDoHQlJNTJng_cw',
  //  baseUrl: 'https://webapi5p.r1.rpost.net',
  //  authURL: 'https://webapip.r1.rpost.net',
  //   resetPwdUrl:'https://trackingp.rpost.com/password/',
  //  logoUrl: 'https://assets.r1.rpost.net/assets/portal/tplogo.gif',
  // tokenURL: 'https://auth.r1.rpost.net',
  // ssoURL: 'https://rcap.r1.rpost.net',
  // ssoEnabled: "https://rportalapi.r1.rpost.net",
  // rmailRegistrationLink: "https://app.rmail.com/account",

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
