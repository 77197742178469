import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, CanLoad, Router, Route } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
    providedIn:'root'
})
export class LoginAuthGuard implements CanActivate {

    constructor(private authService: AuthService,
        private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        
        return this.checkLoggedIn(state.url, route);
    }

    // canLoad(route: Route): boolean {
    //     return this.checkLoggedIn(route.path || '');
    // }

    checkLoggedIn(url: string, route: ActivatedRouteSnapshot): boolean {

        if (route.queryParams != null && route.queryParams.rk != '' && route.queryParams.rk != null && route.queryParams.ru != '' && route.queryParams.ru != null) {
            
            return true;
        }

        if (this.authService.isLoggedIn()) {
            
            this.router.navigate(['/trackingesign'])
            return false;
        }
        
        return true;

    }
}
