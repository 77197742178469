import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPreloadSelectStrategy } from './app-preload-select-strategy';
import { AuthGuardService } from './auth/auth-guard.service';
import { LoginAuthGuard } from './auth/login.auth.guard.service';
//import { ResetPasswordAuthGuard } from './auth/reset-password.auth.guard.service';
import { LoginComponent } from './components/login/login.component';
import { MessageDetails } from './components/message-details/message-details.component';
import { ResetPassword } from './components/reset-password/reset-password.component';
import { TrackingEsignComponent } from './components/tracking-esign/tracking-esign.component';
import { UserRegistrationComponent } from './components/user-registration/user-registration.component';
import { TackingResolverResolver } from './tacking-resolver.resolver';
import { BrandingResolverResolver } from './branding-resolver.resolver';

const routes: Routes = [
  { path: 'login', component: LoginComponent, data: { preload: false }, canActivate: [LoginAuthGuard] },
  { path: 'userregistration', component: UserRegistrationComponent },
  {
    path: 'trackingesign', component: TrackingEsignComponent, canActivate: [AuthGuardService], 
    resolve: { trackingesign: TackingResolverResolver, branding: BrandingResolverResolver }
  },
  { path: 'messagedetails/:trackingId', component: MessageDetails, canActivate: [AuthGuardService] },
  { path: 'password/:Id', component: ResetPassword },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: AppPreloadSelectStrategy})],
  providers: [ AppPreloadSelectStrategy ],
  exports: [RouterModule]
})
export class AppRoutingModule {

 }
