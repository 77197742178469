import { Component, HostListener } from '@angular/core';
import { RefreshTokenService } from './common/refreshToken.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WebTracking';

  constructor(private refresh: RefreshTokenService) {
    
    if (localStorage.getItem('user')) {
      localStorage.setItem('reload', "true");
      this.refresh.startTimer();
    }

  }
}
