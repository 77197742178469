import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { TrackingService } from './trackingservice/tracking.service';
import { AuthService } from './auth/auth.service';
import { UserSessionService } from './common/user-session.service';
import { catchError, map } from 'rxjs/operators';
import { ITrackingAccess } from './auth/iapp-user';
import { AppError } from './common/app-error';

@Injectable({
  providedIn: 'root'
})
export class TackingResolverResolver implements Resolve<any> {

  constructor(private router: Router, private trackingService: TrackingService,
    private user: UserSessionService
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    if (localStorage.getItem('tAccess') == null && localStorage.getItem('tAccess') == undefined) {

      return this.trackingService.getTrackingAccess().pipe(
        map(m=>{
          
        var response = m as ITrackingAccess;

        var result = response.resultContent;
        result.webTrackingAccess = result.webTrackingAccess != null && result.webTrackingAccess != undefined ? result.webTrackingAccess : false;
        this.user.setTrackingAccess(result);
      }),
        catchError(error => {

        return of('No data');
      }));
    }
    return of(true);

  }

  handleError(errorResponse: any) {
    console.log("error responce in resolver ", errorResponse.status);
  }

  getTrackingaccessInfo(): any {

    this.trackingService.getTrackingAccess().subscribe((resp: any) => {

      if (!(resp instanceof AppError)) {

        var response = resp as ITrackingAccess;

        var result = response.resultContent;
        result.webTrackingAccess = result.webTrackingAccess != null && result.webTrackingAccess != undefined ? result.webTrackingAccess : false;
        this.user.setTrackingAccess(result);
      }

      return null;

    });

  }


}
