import { DataApiService  } from './../common/data-api.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IAppUser, ILogin, ILoginAccess, LoginToken } from './iapp-user';
import { UserSessionService } from '../common/user-session.service';
import { AppError } from '../common/app-error';
import { AppConfigService } from '../app-config.service';

@Injectable()
export class AuthService {

  validTokenUrl = 'api/v1/Account/ValidateToken';
  validClientUserTokeUrl = 'api/v1/Account/ValidateClientToken';
  brandUrl = 'api/v1/brand?emailaddress={email}&bkey=WI_RMailWebApp';
  redirectUrl: string;
  passwordUrl: string;

  clientId: string;

  appUser: IAppUser;

  constructor(
    private user: UserSessionService,
    private data: DataApiService,
    private appConfig: AppConfigService) { }

  isLoggedIn(): boolean {
    //if (this.appUser === undefined || this.appUser === null) {
      if (localStorage.getItem('user')) {
        this.appUser = JSON.parse(localStorage.getItem('user')||'{}');
        this.appUser.isAuthenticated = this.appUser.isExternalLogin ? !this.appUser.isAuthenticated : this.appUser.isAuthenticated;
        
        return (!!this.appUser && this.appUser.isAuthenticated);
      }
    //}
    return false;
  }
  getToken(): string {
    //get token from appUser.token if exist, otherwise try usersession token
    // if (this.appUser !== undefined && this.appUser !== null) {
    //   if (this.appUser.token !== undefined && this.appUser.token !== null) {
    //     return this.appUser.token;
    //   } else {
    //     return this.user.getToken();
    //   }
    // } else {
    //     return this.user.getToken();
    // }
    return this.user.getToken();
  }
  
  forgotPassword(reqObj:any): Observable<any> {
    return this.data.apiForgotPassword(reqObj);
  }
  resetPassword(pwd1: string, pwd2: string, code: string): Observable<any> {
    return this.data.apiResetPassword(pwd1, pwd2, code);
  }

  validatestring(code: string):Observable<any> {   // S12-1176
    return this.data.apiValidateCode(code);
  }

  apiRegister(resgister: any):Observable<any>{

    return this.data.apiRegister(resgister);
  }

  login(logIn: ILogin): Observable<any> {
    const pwd = encodeURIComponent(logIn.password);
    return this.data.apiToken(logIn.email.toLowerCase(), pwd)
      .pipe(
        tap(resp => {
          if (!(resp instanceof AppError)) {
            var result = resp as ILoginAccess;
            const user = this.getNewUser();
            user.isAuthenticated = true;
            user.userEmail = logIn.email.toLowerCase();
            user.token = result.resultContent.access_token;
            user.rememberEmail = logIn.rememberMe;
            user.staySignIn = logIn.staySignIn;
            user.loginToken = result.resultContent;
            user.refresh_token = result.resultContent.refresh_token;
            this.appUser = user;
            this.user.setUser(user);
          }
        }));
  }

  logout(): void {

    // if (this.appUser != null && this.appUser != undefined) {
    //   if (this.appUser.rememberEmail !== true) {
    //     this.appUser.userEmail = '';
    //     this.appUser.isAuthenticated = false;
    //   }
    //   if (this.appUser.staySignIn !== true) {
    //     this.appUser.token = '';
    //   }
    //   this.appUser.userPlanType = '';
    //   this.user.setUser(this.appUser);
    // }

    var trustDeviceDetailsInfo = {
      trustDeviceInfo: false,
      trustDeviceExpirationDate: new Date()
    }

    var trustDeviceDetails = localStorage.getItem("trustDeviceDetails") != null && localStorage.getItem("trustDeviceDetails") != undefined ? JSON.parse(localStorage.getItem("trustDeviceDetails") || '{}') : trustDeviceDetailsInfo;
    localStorage.clear();
    localStorage.setItem("trustDeviceDetails", JSON.stringify(trustDeviceDetails));
  }

  loginExternal(loginData: any) {

    const user = this.getNewUser();
    user.isAuthenticated = false;
    user.userEmail = loginData.email;
    user.token = loginData.userToken;
    user.rememberEmail = false;
    user.staySignIn = false;
    user.isExternalLogin = true;
    this.appUser = user;
    this.user.setUser(user);
  }

  saveUserDetailsfromMFA(loginDetails: any) {

    var mfaResponseData = {
      trustDeviceInfo: loginDetails.TrustDeviceInfo,
      trustDeviceExpirationDate: loginDetails.TrustDeviceExpirationDate,
      redirectedFrom: loginDetails.RedirectedFrom,
      refresh_Token: loginDetails.JwtToken.refresh_token,
      refresh_Expires: loginDetails.JwtToken[".refresh_expires"],
      access_Token: loginDetails.JwtToken.access_token,
      expires_In: loginDetails.JwtToken.expires_in,
      emailAddress: loginDetails.JwtToken.emailaddress,
      issues: loginDetails.JwtToken[".issued"],
      expires: loginDetails.JwtToken[".expires"]
    }

    var trustDeviceDetails = {
      trustDeviceInfo: loginDetails.TrustDeviceInfo,
      trustDeviceExpirationDate: loginDetails.TrustDeviceExpirationDate
    }

    localStorage.setItem("trustDeviceDetails", JSON.stringify(trustDeviceDetails));

    var lclUserDetails = localStorage.getItem('user');
    
    var userDetails = lclUserDetails != null && lclUserDetails != undefined ? JSON.parse(lclUserDetails) : null;
    
    if (userDetails != null && userDetails != undefined) {

      userDetails.token = mfaResponseData.access_Token;
      userDetails.loginToken[".expires"] = loginDetails.JwtToken[".expires"];
      userDetails.loginToken[".issued"] = loginDetails.JwtToken[".issued"];
      userDetails.loginToken[".refresh_expires"] = loginDetails.JwtToken[".refresh_expires"];
      userDetails.loginToken.emailaddress = mfaResponseData.emailAddress;
      userDetails.loginToken.expires_in = mfaResponseData.expires_In;
      userDetails.loginToken.access_token = mfaResponseData.access_Token;

      localStorage.setItem('user', JSON.stringify(userDetails));
    }
    else {
      
      const user = this.getNewUser();
      user.isAuthenticated = true;
      user.userEmail = mfaResponseData.emailAddress;
      user.token = mfaResponseData.access_Token;
      user.rememberEmail = false;
      user.staySignIn = false;
      user.isExternalLogin = false;
      user.loginToken[".expires"] = loginDetails.JwtToken[".expires"];
      user.loginToken[".issued"] = loginDetails.JwtToken[".issued"];
      user.loginToken[".refresh_expires"] = loginDetails.JwtToken[".refresh_expires"];
      user.loginToken.emailaddress = mfaResponseData.emailAddress;
      user.loginToken.expires_in = mfaResponseData.expires_In;
      user.loginToken.access_token = mfaResponseData.access_Token;
      this.appUser = user;
      this.user.setUser(user);
    }

  }

  getNewUser(): IAppUser {
    return {
      userId: null,
      userEmail: '',
      password: '',
      rememberEmail: false,
      staySignIn: false,
      token: '',
      refresh_token:'',
      isAuthenticated: false,
      isExternalLogin: false,
      userName: '',
      userPlanType: '',
      hdo: null,
      brandCssUrl: '',
      loginToken: new LoginToken(),
      loginDate: new Date()
    };
  }

  getBrand(email: string): Observable<any> {
    const url = this.brandUrl.replace('{email}', email);
    return this.data.apiGetById(url);
  }

  getRefreshTokenlogin() {

    return this.data.refreshToken()
      .pipe(
        tap(resp => {

          if (!(resp instanceof AppError)) {
            var result = resp as ILoginAccess;

            var userDetails = JSON.parse(localStorage.getItem('user') || '{}');
            userDetails.token = result.resultContent.access_token;
            userDetails.loginToken[".expires"] = result.resultContent[".expires"];
            userDetails.loginToken[".issued"] = result.resultContent[".issued"];
            userDetails.loginToken.emailaddress = result.resultContent.emailaddress;
            userDetails.loginToken.expires_in = result.resultContent.expires_in;
            userDetails.loginToken.access_token = result.resultContent.access_token;

            localStorage.setItem('user', JSON.stringify(userDetails));
          }
          // else {
          //   var error = resp.originalError != null && resp.originalError != undefined && resp.originalError.error != null && resp.originalError.error != undefined && resp.originalError.error.message != null && resp.originalError.error.message != undefined ? resp.originalError.error.message.messageCode : "";
          //   if (error == "RCAP-1000" || error == "RCAP-1001") {
          //     this.logout();
          //     location.href = location.host;
          //   }            
          //}
        }));
  }

  getValidateToken(){

    return this.data.validateToken().pipe(tap(res=>{
      
    }));

  }

  getRevokeToken() {

    return this.data.revokeToken().pipe(tap(res => {

    }));

  }

  getadportal(obj:any){

    return this.data.azurePortalExtToken(obj).pipe(tap(resp=>{

      if (!(resp instanceof AppError)) {

        var result = resp as ILoginAccess;
        const user = this.getNewUser();
        user.isAuthenticated = true;
        user.userEmail = obj.providerUserEmail;
        user.userName = obj.providerUserName;
        user.token = result.resultContent.access_token;
        user.rememberEmail = false;
        user.staySignIn = false;
        user.loginToken = result.resultContent;
        user.refresh_token = result.resultContent.refresh_token;
        this.appUser = user;
        this.user.setUser(user);
        this.user.setAzureAccess(true);
      }

    }));
  }

  azureLogOut() {

    var emailAddress = this.user.getUser();
    var externalData = JSON.parse(localStorage.getItem("provider_access_token") || "");

    this.logout();
    location.href = this.appConfig.ssoURL + "/rpost-authentication-logout?logoutcallbackurl=" + location.href + "&emailAddress=" + emailAddress.userEmail + "&ssotype=" + externalData.ssotype;
  }

  googleCaptchaTokenVerification(googleCaptchaToken: any) {

    return this.data.googleCaptchaVerificationToken(googleCaptchaToken)
      .pipe(
        tap(resp => { 
          
        }));
  }

  ssoEnabledValidationCheck(emailAddress: any) {

    return this.data.ssoEnabledCheck(emailAddress).pipe(tap(resp => {

    }));

  }
  
  // S12-1158
  getIPAddress(){

    return this.data.getIPAddress().pipe(tap(res=>{

    }));
  }
  
}
