
export class Recipients {
  address: string;
  timeSent: string;
  eSignStatus: string;
  destinationShortURL: string;
  ipAddress: string;
  timeSigned: string;
  linkClickedTime: string;
  timeOpened: string;
  linkClickedIp: string;
  declineIp: string;
  cancelledIp: string;
  progress: String;
  secondStatus: string;
  progressType: string;
  actionDate: string;
  statusText: string;
  orderNum: number;
  message: string;
  cancelledTime: string;
  declineTime: string;
  status: Status[];
}



export class Status {
  status: string;
  statusUpdated: any;
  IPaddress: string;
}

export interface IRecipients {
  Address: string;
  TimeSent: string;
  ESignStatus: string;
  TimeSigned: string;
  LinkClickedTime: string;
  TimeOpened: string;
  progress: String;
  secondStatus: string;
  progressType: string;
  actionDate: string;
  statusText: string;
  orderNum: number;
  status: Status[];

}
export class MessageDetail {
  messageId: string;
  subject: string;
  dateSent: string;
  senderAddress: string;
  esignSequential: string;
  eSignStatus: string;
  cancelReason: string;
  FinalContractSent: boolean;
  DisplayMessage: string;
  HasMessageExpired: boolean;
  ExpiresInDays: number;
  recipients: Recipients[];
  declineReason: string;
  esignExpirationDays: number;
  esignExpirationDate: string;
  serviceType: string;
}


export interface IMessageDetail {
  messageId: string;
  subject: string;
  esignSequential: string;
  senderAddress: string;
  cancelReason: string;
  dateSent: string;
  ESignStatus: string;
  FinalContractSent: boolean;
  DisplayMessage: string;
  HasMessageExpired: boolean;
  ExpiresInDays: number;
  recipients: Recipients[];
}
export interface IMessages {
  MessageDetail: IMessageDetail;
}

export interface IPageEvent {
  length: number;
  pageIndex: number;
  pageSize: number;
  previousPageIndex: number;
}

export class SortEvent {
  Column: string;
  Direction: string;
}

export interface IreminderResponseData {
  status: string;
  statusCode: string;
  statusText: string;
  success: boolean;
  resultContent: resultContentObj;
}

export class resultContentObj {

  destinationKey: string;
  messageId: string;
  remindersSent: remindersSent[]
}

export class remindersSent {
  reminderType: string;
  reminderSentOn: string
}