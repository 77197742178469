export interface IAppUser {
  userId?: number | null;
  userEmail: string;
  password: string;
  rememberEmail: boolean;
  staySignIn: boolean;
  token: string;
  refresh_token: string;
  isAuthenticated: boolean;
  isExternalLogin: boolean | false;
  userName: string;
  /* 1-rmailTrial, 2-rmailShared , 3-paidNotShared*/
  userPlanType?: string;
  hdo?: number | null;
  brandCssUrl?: string;
  loginToken: LoginToken,
  loginDate: Date
}

export interface ILogin {
  email: string;
  password: string;
  rememberMe: boolean;
  staySignIn: boolean;
}

export interface ILoginResp {
  access_token: string;
}

export interface IBrandHF {
  status: string;
  statusCode: string;
  statusText: string;
  message: any;
  success: boolean;
  resultContent: IBrandContent;
}

export interface IBrandContent {
  headerHtml: string;
  footerHtml: string;
}

export interface ITrackingAccess {
  status: string;
  statusCode: string;
  statusText: string;
  success: boolean;
  resultContent: ITracking;
}

export interface ITracking {
  address: string;
  customerId: string;
  userId: string;
  webTrackingAccess: boolean;
}


export interface ILoginAccess{
  loginToken: any;
  status: string;
  statusCode: string;
  statusText: string;
  success: boolean;
  resultContent: LoginToken;
}

export class LoginToken {
  ".expires": string;
  ".issued": string;
  ".refresh_expires": string;
  access_token: string;
  emailaddress: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
}

export interface ISSOAccess {
  status: string;
  statusCode: number;
  statusText: string;
  success: boolean;
  resultContent: ISSO;
}

export interface ISSO {
  //DomainAddress: string;
  //DomainEnabled: boolean;
  //SSOEnabled: boolean;
  ssoEnabled: boolean;
  //SSoEnterpriseSettingID: number;
  //SSORst: number;
  message: string;
}