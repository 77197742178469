import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppError } from 'src/app/common/app-error';
import { ISSOAccess } from 'src/app/auth/iapp-user';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ForgotPasswordComponent>, private formBuilder: FormBuilder,
    private authService: AuthService, public snackBar: MatSnackBar) {

  }

  forgotForm: FormGroup;
  validEmail: boolean = false;

  ngOnInit() {

    this.buildFormBuilder();

  }

  buildFormBuilder(){

    this.forgotForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, 
                                  Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")])      
    });
  }

  get validate() { return this.forgotForm.controls; }

  onConfirm(form: FormGroup) {

    if (this.forgotForm.invalid) {
      if (this.forgotForm.controls.email.errors || this.forgotForm.controls.email.value == '')
        this.validEmail = true;

      return;
    }

    this.validEmail = false;

    var email = form.value.email;
    
    this.ssoEnableCheck(email,true);

  }

  onEmailBlur(event: any) {

    this.ssoEnableCheck(event.target.value, false);

  }

  ssoEnableCheck(value: any, isLogin:any) {

    this.authService.ssoEnabledValidationCheck(value).subscribe(resp=>{

      var result = resp as ISSOAccess;
      if (resp instanceof AppError) {
        resp.Message;
      }
      else {

        if (result.statusCode == 200 && result.resultContent.ssoEnabled == true) { //S12-1195
          var msg = "Your IT administrator has restricted resetting passwords directly on this application for your domain. Please use your Single Sign-On methods to reset your password, or reach out to your IT administrator for assistance.";
          this.openSnackBar(msg, "");
        }
        else if (result.statusCode == 200 && result.resultContent.ssoEnabled == false) {

          if (isLogin) {
            
            var email = value;
            var callbackUrl = "";
            var appLang = "";
            var loginURL = location.origin;

            var obj={
              email:value,
              loginURL:location.origin
            }

            this.authService.forgotPassword(obj).subscribe(resp => {

              if (resp instanceof AppError) {
                var msg: string = resp.Message;
                if (resp.originalError.status == 400 && msg.includes("Couldn't find") || msg.includes('Bad Request') || (resp.MessageId == "ACCT-1036")) {
                  this.openSnackBar("User is not registered in the application. Please register the user and try again.", "");//S12-1183
                  this.buildFormBuilder();
                }

                if (resp.originalError.status == 400 && msg.includes("External login")) {
                  this.openSnackBar("Cannot reset password for External login accounts: " + email, "");
                  this.buildFormBuilder();
                }

                if (msg.includes('OK')) {
                  this.openSnackBar("Password reset email sent", "", "green-snackbar");
                  this.dialogRef.close();
                }
              }
              else if (resp.Status.toLowerCase() == "success") {

                var responseMsg = "";

                if (resp.Message != null && resp.Message != undefined) {
                  resp.Message.forEach((item: any) => {
                    this.openSnackBar(item.Message, "");
                  });
                }
                
                this.dialogRef.close();
              }

            });

          }
        }
      }

    });

  }

  onClose() {

    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string, msgColor: string = 'red-snackbar') {
    this.snackBar.open(message, action, {
        duration: 5000,
        verticalPosition: 'top',
        panelClass: [msgColor]
    });
}

}
