import { AfterViewChecked, AfterViewInit, Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/app-config.service';
import { UserSessionService } from 'src/app/common/user-session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewChecked,OnInit {

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(private sanitizer: DomSanitizer, private user: UserSessionService,
    private config: AppConfigService) { }
  

  public displayUser: string = "";
  hideElement = false;
  HeaderHtml: SafeHtml;
  appLangs = [];


  ngOnInit() {

    // setTimeout(() => {
      
    //   const brand = this.user.getBrand();

    //   if (brand !== null) {
    //     this.HeaderHtml = this.sanitizer.sanitize(SecurityContext.HTML, brand.headerHtml) || {};
    //   } else {
    //     this.HeaderHtml = '';
    //   }
      
    // }, 1000);

  }

  ngAfterViewChecked() {

    const brand = this.user.getBrand();

      if (brand !== null) {
        this.HeaderHtml = this.sanitizer.sanitize(SecurityContext.HTML, brand.headerHtml) || {};
      } else {
        this.HeaderHtml = '';
      }

  }


}
