<div class="login-form">
    <header class="header">
       <!--  <div style="text-align: left;">
            <a href="http://www.rmail.com/" title="RMail" rel="home">
                <img style="width: 150px;" src="../../../assets/images/rmail-logo.png" alt="Rmail Logo" />
            </a>
        </div>
        <div>
            <button mat-button [matMenuTriggerFor]="menu">
               User
            </button>
              
            <mat-menu #menu="matMenu">
              <button mat-menu-item>Logout</button>
               <button mat-menu-item>CSS</button>
              <button mat-menu-item>JQuery</button>
            </mat-menu>
           
        </div> -->

        <div>
            <div class="brand-header">
                <div [innerHtml]="HeaderHtml">

                </div>
                <!-- <div class="header-app-logo">
                    <img src="http://open.qa.rpost.net/open/brand/e01LwlYpx8xO7NvUkKBpvYlJ5WUEuGVmzH2edEMqxevPDjBguKVJXh86erZ7RTO9XfAj_XsKy3pvfB2y510zPA=="
                        alt="RMail Web">
                </div>
                <div class="header-text"> Web Tracking</div>
                <div class="header-co-logo">
                    <img src="http://open.qa.rpost.net/open/brand/e01LwlYpx8xO7NvUkKBpvXbwv1otQFw6pxxTrVv_dxdcNCuSJ9J6ztlnV663su5DqBbH83WWNAYl76HpyXlJsw=="
                        alt="RPost">
                </div> -->
            </div>
        </div>

    </header>
    
</div>