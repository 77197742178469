<section class="wrapper flex-row-center min-h">
    <!-- register form -->
    <div class="w-50">
       <form novalidate [formGroup]="registrationForm" (ngSubmit)="onSubmit(registrationForm)">

         <div class="card-rs p-5 text-left" *ngIf="isRegisterSuccesful"> 
            <h3>Final <span class="text-green">step!</span></h3>
            <p class="text-left pb-20">To complete the registration process, please click the <b>ACTIVATION LINK</b> in the confirmation email sent to your address.If you do not see activation 
               email in your inbox, please check your spam or junk mailbox.</p>
            <p class="text-left pb-20"><b>From:<span class="text-green">support@r1.rpost.net</span> </b> </p>  
            <p class="text-left pb-20">After you click the <b>ACTIVATION LINK</b>, please select the RMail service to sign in to get started.</p>
            <p class="text-left pb-20">
               Visit us at <a href="https://rmail.com/" target="_blank">www.rmail.com</a> to learn about RPost services.
            </p>
         </div>

       <div class="register-form"   *ngIf="!isRegisterSuccesful" >
          <div class="logo1">
             <!-- <a href="http://www.rmail.com/" title="RMail" rel="home"><img src="../../../assets/images/rmail-logo.png"
                alt="Rmail Logo"></a> -->
             <a href="http://www.rmail.com/" title="RMail" rel="home">
             <img src="{{logoURL}}" alt="RMail Web">
             </a>
          </div>
          <small>Let's get started!</small>
          <ul class="form-wrapper">
             <li class="form-row1">
                <div class="form-column1">
                   <div class="password-container">
                      <input type="email" formControlName="email" class="form-control reg-input" name="email"
                         placeholder="Email address" (blur)="onControlBlur($event)" />
                      <i class="fa fa-envelope icon" style="top:13px;"></i>
                       <div class="text-danger popup" *ngIf="validEmail">
                        <span class="popuptext" [ngClass]="{'show': (validEmail)}"> 
                             Please enter email</span>   
                      </div> 
                     <div class="text-danger popup" *ngIf="validEmailPattern">
                        <span class="popuptext" [ngClass]="{'show': (validEmailPattern)}">  
                            Please enter valid email</span>   
                      </div> 
                   </div>
                </div>
                <div class="form-column1">
                   <div class="password-container">
                      <input type="number" formControlName="phoneNumber" class="form-control" name="number"
                         placeholder="Phone"  maxlength="10"/>
                      <i class="fa fa-phone" style="top:13px;"></i>
                   </div>
                </div>
             </li>

             <li class="form-row1">
                <div class="form-column1">
                   <div class="password-container">
             <div id="passSec">
             <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control reg-input" name="password"
             placeholder="Password"    minlength="8" maxlength="25" (blur)="onControlBlur($event)" 
             (keydown.space)="$event.preventDefault()"/> 
             <i class="fa fa-key" style="top:11px;"></i>
             <i class="fa fa-eye password-toggle" (click)="togglePasswordVisibility()" style="left: 272px; top:13px;"></i>
             <div *ngIf="validPassword" class="text-danger popup">
               <span class="popuptext" [ngClass]="{'show': (validPassword)}">  
                    Please enter password</span> 
             </div>
             <div *ngIf="validPasswordPattern" class="text-danger popup">
               <div class="popuptext1" [ngClass]="{'show': (validPasswordPattern)}">
                     Password Must contain minimum of 8 characters,one uppercase character, one lowercase character, 
                     one special character, one number and no spaces</div> 
             </div>
             </div> 
             </div>
             </div>
             <div class="form-column1">
                <div id="passSecConfirm">
                   <div class="password-container">
                      <input type="{{ showConfirmPassword ? 'text' : 'password' }}" formControlName="confirmPassword" class="form-control reg-input"
                      id="confirmpassword" name="confirmpassword" placeholder="Confirm password" minlength="8" maxlength="25" 
                      (blur)="onControlBlur($event)" (keydown.space)="$event.preventDefault()"  />
                      <i class="fa fa-key" style="top:13px;"></i>
                      <i class="fa fa-eye password-toggle" (click)="toggleConfirmPasswordVisibility()" style="left: 272px;top:11px;"></i>
                      <!-- <div *ngIf="validConfirmPassword" class="text-danger popup">
                        <span class="popuptext" [ngClass]="{'show': (validConfirmPassword)}">  
                            Please enter confirm password</span> 
                      </div> -->
                      <div *ngIf="validMatchPassword" class="text-danger popup">
                        <span class="popuptext" [ngClass]="{'show': (validMatchPassword)}">  
                            Password and confirm password must be same</span> 
                      </div>
                   </div>
                  </div>
             </div>
             </li>
             <li class="form-row1">
                <div class="form-column1">
                   <div class="password-container">
                      <input name="firstname" formControlName="firstName"  class="form-control" placeholder="First name"
                         (blur)="onControlBlur($event)"  />
                      <i class="fa fa-user" style="top:13px;"></i>
                      <div *ngIf="validFirstName" class="text-danger popup">
                        <span class="popuptext" [ngClass]="{'show': (validFirstName)}">  
                            Please enter first name</span> 
                      </div>
                   </div>
                </div>
                <div class="form-column1">
                   <div class="password-container">
                      <input type="text" formControlName="lastName" class="form-control" name="lastname"
                         placeholder="Last name" (blur)="onControlBlur($event)" />
                      <i class="fa fa-user" style="top:13px;"></i>
                      <div *ngIf="validLastName" class="text-danger popup">
                        <span class="popuptext"  [ngClass]="{'show': (validLastName)}">  
                            Please enter last name</span> 
                      </div>
                   </div>
                </div>
             </li>
             <li class="form-row1">
             <div class="form-column1">
                <div  class="captcha-style">
                   <re-captcha formControlName="captcha" name="captcha" (resolved)="resolved($event)" (errored)="errored($event)"
                   [(ngModel)]="captchaResponse"></re-captcha>
                   <div class="alert alert-danger" *ngIf="validGoogleCaptcha">
                      Please validate Captcha
                   </div>
                  </div>
             </div>
             <div class="form-column1 pt-30">
               <mat-checkbox class="example-margin ml-3"  formControlName="iAgree">
                      <label class="form-check-label" for="terms">
                        I agree to the  <a href="https://rpost.com/legal-notices/terms-and-conditions" target="blank">Terms and Conditions</a>
                      </label></mat-checkbox>
                      <div class="alert alert-danger" *ngIf="validAgree"> Please Check the Terms and Conditions</div>
             </div>
            </li>
             <div class="button-container">
                <li class="register-form-btn">
                   <button type="submit" [disabled]="registrationForm.invalid" [ngClass]="{'disabled-button': registrationForm.invalid, 'enabled-button': !registrationForm.invalid}"
                   id="btnSubmit" title="Click to submit"> 
                   Register
                   </button>
                </li>
             </div>
             <div class="or_options text-center">
                <div class="or_text">
                   <span style="font-size: 15px;"> or <a  (click)="onCancel()"  class="ml-2" style="color: #c3272e; font-weight: 600;">Sign in</a> </span>
                </div>
             </div>
          </ul>
       </div>
       </form>
    </div>
 </section>
 <!-- <div id="loading-div">
    <img ng-src="../images/FhHRx.gif" alt="Loading ..." class="processing-icon" />
    </div> -->