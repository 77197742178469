import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { formatRelativeWithOptions } from 'date-fns/fp';
import { AuthService } from 'src/app/auth/auth.service';
import { ISSOAccess } from 'src/app/auth/iapp-user';
import { AppError } from 'src/app/common/app-error';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.scss']
})
export class UserRegistrationComponent implements OnInit {

  constructor(private formBuilder: FormBuilder, private router: Router,private appConfigService: AppConfigService,
    private authService: AuthService, public snackBar: MatSnackBar) {

  }

  registrationForm: FormGroup;
  logoURL = ""; //S12-1177

  get validate() { return this.registrationForm.controls; }

  validFirstName: boolean = false;
  validLastName: boolean = false;
  validEmail: boolean = false;
  validPassword: boolean = false;
  validConfirmPassword: boolean = false;
  validEmailPattern: boolean = false;
  validPasswordPattern: boolean = false;
  validConfirmPasswordPattern: boolean = false;
  validMatchPassword: boolean = false;
  validGoogleCaptcha: boolean = false;
  validAgree: boolean = false;
  captchaResponse: string | null;
  userIPAddress: string = "";
  phoneNumber: string;

  showPassword: boolean = false;  //S12-1177
  showConfirmPassword: boolean = false; //S12-1177
  isRegisterSuccesful:boolean = false;//S12-1177
  
  ngOnInit() {

    this.buildRegistrationForm();
    this.getIPAddress(); // S12-1158

    // this.logoURL = this.appConfigService.logoUrl; // S12-1177
    this.logoURL = '../../assets/images/rmail-latest-logo.png';

  }

  buildRegistrationForm(){

    this.registrationForm = this.formBuilder.group({
      firstName: new FormControl('',[Validators.required]),
      lastName: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.required, 
                                 Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")]),
      password: new FormControl('',[Validators.required,
                                    Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,25}$"),
                                     Validators.minLength(8), Validators.maxLength(25)]),
      confirmPassword: new FormControl('',[Validators.required,
                                           Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,25}$"),
                                            Validators.minLength(8), Validators.maxLength(25)]),
                                            
      captcha: new FormControl('', { validators: [Validators.required], }),
      phoneNumber: new FormControl('',[Validators.maxLength(10)]),
      iAgree: new FormControl('', Validators.requiredTrue)

    });
  }

  //S12-1177 
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  
  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  } //S12-1177

  onSubmit(form: FormGroup) {

    if (this.registrationForm.invalid) {

      var validForm = this.validateRegistrationForm(this.registrationForm);

      if (!validForm)
        return;
    }

    if (form.value.password != form.value.confirmPassword) {
      this.validMatchPassword = true;
      return;
    }


    var pNumber = form.value.phoneNumber != null && form.value.phoneNumber != "" ? form.value.phoneNumber : ""; 
    var pNumberAsString = String(pNumber);  //S12-1177

    var obj: any = {
      FirstName: form.value.firstName,
      LastName: form.value.lastName,
      Email: form.value.email,
      Password: decodeURIComponent(form.value.password),
      ConfirmPassword: decodeURIComponent(form.value.confirmPassword),
      ConfirmationCallbackUrl: "",
      RegistrationApp: "WebTracking",
      IPAddress: this.userIPAddress,//S12-1158
      PhoneNumber:pNumberAsString   
    }

    var googleRes = this.authService.googleCaptchaTokenVerification(this.captchaResponse)
      .subscribe(resp => {
        // this.captchaResponse = null;
        // setTimeout(() => {
        //   grecaptcha.reset();
        // }, 100);       //S12-1177 
        if (resp instanceof AppError) {
          var err = resp;
          this.captchaResponse = null;
          setTimeout(() => {
            grecaptcha.reset();
          }, 100);
        }
        else {

          this.ssoEnabledCheck(form.value.email, true, obj);

        }

      });
    
  }

  validateRegistrationForm(registrationForm: any) {

    if (registrationForm.controls.firstName.errors && (registrationForm.controls.firstName.value != '' || registrationForm.controls.firstName.value == ''))
      this.validFirstName = true;

    if (registrationForm.controls.lastName.errors && (registrationForm.controls.lastName.value != '' || registrationForm.controls.lastName.value == ''))
      this.validLastName = true;

    if (registrationForm.controls.email.errors && (registrationForm.controls.email.value != '' || registrationForm.controls.email.value == ''))
      this.validEmail = true;

    if (registrationForm.controls.password && (registrationForm.controls.password.value != '' || registrationForm.controls.password.value == ''))
      this.validPassword = true;

    if (registrationForm.controls.confirmPassword && (registrationForm.controls.confirmPassword.value != '' || registrationForm.controls.confirmPassword.value == ''))
      this.validConfirmPassword = true;

    if (registrationForm.controls.captcha.errors)
      this.validGoogleCaptcha = true;

    if (registrationForm.controls.iAgree.errors)
    this. validAgree = true; 
    
    return false;
  }

  onControlBlur(event: any) {

    var registrationForm = this.registrationForm;

    switch (event.target.name) {
      case "firstname":
        this.validFirstName = event.target.value != "" ? false : true;
        break;
      case "lastname":
        this.validLastName = event.target.value != "" ? false : true;
        break;
      case "email":

        if (registrationForm.controls.email.errors?.pattern && event.target.value != '')
          this.validEmailPattern = true;
        else
          this.validEmailPattern = false;

        this.validEmail = event.target.value != "" ? false : true;
        this.ssoEnabledCheck(event.target.value, false, null);
        break;
      case "password":

        if ((registrationForm.controls.password.errors?.pattern || registrationForm.controls.password.errors?.minlength) && event.target.value != "")
          this.validPasswordPattern = true;
        else
          this.validPasswordPattern = false;

        this.validPassword = event.target.value != "" ? false : true;
        break;
      case "confirmpassword":

        if ((registrationForm.controls.confirmPassword.errors?.pattern || registrationForm.controls.confirmPassword.errors?.minlength) && event.target.value != "")
          this.validConfirmPasswordPattern = true;
        else
          this.validConfirmPasswordPattern = false;

        if (registrationForm.controls.password.value != registrationForm.controls.confirmPassword.value)
          this.validMatchPassword = true;
        else
          this.validMatchPassword = false;

        this.validConfirmPassword = event.target.value != "" ? false : true;
        break;

    }
  }


  ssoEnabledCheck(value: any, isRegister: any, saveObj: any) {

    this.authService.ssoEnabledValidationCheck(value).subscribe(resp=>{

      var result = resp as ISSOAccess;
      if (resp instanceof AppError) {
        resp.Message;
      }
      else {

        if (result.statusCode == 200 && result.resultContent.ssoEnabled == true) { //S12-1195
          var msg = "You are pre-registered by your IT administrator. Use your Single Sign-On to access the application. Your IT administrator has restricted other forms of registration.";
          this.openSnackBar(msg, "");
        }
        else if (result.statusCode == 200 && result.resultContent.ssoEnabled == false) {

          if (isRegister) {

            var obj = {
              FirstName: saveObj.FirstName,
              LastName: saveObj.LastName,
              Email: saveObj.Email,
              Password: decodeURIComponent(saveObj.Password),
              ConfirmPassword: decodeURIComponent(saveObj.ConfirmPassword),
              ConfirmationCallbackUrl: "",
              RegistrationApp: "WebTracking",
              IPAddress: saveObj.IPAddress,// S12-1158
              PhoneNumber:saveObj.PhoneNumber  //S12-1177 
            }

            this.authService.apiRegister(obj).subscribe((resp: any) => {

              // setTimeout(() => {
              //   grecaptcha.reset();
              // }, 100);

              if (resp instanceof AppError) {

                const msg: string = resp.Message;

                if (msg.includes("Already Exist"))
                  this.openSnackBar("Email Id already Exists.", "");

                if (msg.includes("(200): OK")) {   //S12-1250

                  var text = resp.originalError.error.text;

                  if (text.includes("pending")) {
                    this.openSnackBar("Account pending activation.", "");
                    this.router.navigate(['/login']);
                  }

                  if (text.includes("already registered")) {
                    this.openSnackBar("Email account is already registered.", "");
                  }

                  if (text.includes("successful and confirmation")) {
                    // this.openSnackBar("Registration successful and confirmation email sent.", "");
                    // this.router.navigate(['/login']);
                    this.isRegisterSuccesful = true;
                  }

                }
                else {

                  if (resp.originalError.error != null && resp.originalError.error.resultContent != null && resp.originalError.error.resultContent.errors != null && resp.originalError.error.resultContent.errors.length > 0) {

                    var errMsg = resp.originalError.error.resultContent.errors;
                    for (let i = 0; i < errMsg.length; i++) {

                      var msgError = errMsg[i];
                      this.openSnackBar(msgError.message, "");
                    }
                  }
                  else
                    this.openSnackBar(" Error occured while registration.", "");
                }

              }
              else {

                // this.openSnackBar("Registration successful and confirmation email sent.", "");
                // this.router.navigate(['/login']);
                this.isRegisterSuccesful = true;

              }
              this.captchaResponse = null;
              setTimeout(() => {      //S12-1191
              grecaptcha.reset();
              }, 100);

            });

          }
        }
      }

    });

  }

  // to get the user IP address S12-1158
  getIPAddress() {

    this.userIPAddress = "";

    this.authService.getIPAddress().subscribe((resp: any) => {
      
      this.userIPAddress = resp.ip;
    });
  }

  onCancel() {

    this.router.navigate(['/login']);
  }

  resolved(event: any) {

    if (event === null) {
      setTimeout(() => {
        grecaptcha.reset();
      },100);
    }
  }
 
  errored(event: any) {

    this.openSnackBar("Retry recaptcha.","");

    setTimeout(() => {
      grecaptcha.reset();
    },100);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }

}
