import { AfterViewInit, Component, EventEmitter, Inject, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { differenceInDays, subDays } from 'date-fns';
import format from 'date-fns/format';
import { AppError } from 'src/app/common/app-error';
import { ImessageIds, IsenderRecipient, messageIDValue, SearchFilter, senderRecipientValue } from 'src/app/models/search-filter.model';
import { TrackingService } from 'src/app/trackingservice/tracking.service';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { catchError, debounceTime, delay, distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IPageEvent, SortEvent } from 'src/app/models/tracking-details.model';



@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss']
})

export class SearchFilterComponent implements AfterViewInit, OnInit, OnDestroy {

  @Output()
  dateChange: EventEmitter<MatDatepickerInputEvent<any>> = new EventEmitter();


  showField: boolean = false;
  setToMaxDate: Date | null;
  setFromMaxDate: Date | null;
  setToMinDate: Date | null;
  setFromMindate: Date | null;
  messageData: any = [];
  messageOptions: ReplaySubject<messageIDValue[]> = new ReplaySubject<messageIDValue[]>();
  senderRecipientData: any;
  senderRecipientOptions: ReplaySubject<senderRecipientValue[]> = new ReplaySubject<senderRecipientValue[]>();
  protected _onDestroy = new Subject<void>();
  protected _onSenderDestroy = new Subject<void>();
  searchingMessage: boolean = false;
  searchingSR: boolean = false;


  constructor(public dialogRef: MatDialogRef<SearchFilterComponent>, private formBuilder: FormBuilder,
    private trackingService: TrackingService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar) {

    this.showField = data?.showField;

  }

  searchForm: FormGroup;


  ngOnInit() {

    this.formBuild();

    this.searchForm.controls.messageType.setValue('0');
    this.searchForm.controls.messageStatus.setValue('0');

    this.setToMaxDate = new Date();
    this.setFromMaxDate = new Date();

    if (localStorage.getItem('searchFilters') != null) {
      var searchValues = JSON.parse(localStorage.getItem('searchFilters') || '{}');
      this.setValuestoContorls(searchValues);
    }

    this.getMessageDataonSearch();

    if (this.showField) {
      this.getSenderRecipientDataonSearch();
    }

  }

  ngAfterViewInit(){

    if (localStorage.getItem('searchFilters') != null) {

      if (this.messageData != null && this.messageData != undefined && this.messageData.length > 0) {
        this.searchForm.controls.messageID.setValue(this.messageData[0]);
        this.messageOptions.next(this.messageData.slice());
      }

      if (this.senderRecipientData != null && this.senderRecipientData != undefined && this.senderRecipientData.length > 0) {
        this.searchForm.controls.senderRecipient.setValue(this.senderRecipientData[0]);
        this.senderRecipientOptions.next(this.senderRecipientData.slice());
      }
    }

  }


  messagestatus: any = [
    {
      statusName: "Submitted For Signature",
      statusId: 1
    },
    {
      statusName: "Signature In Progress",
      statusId: 2
    },
    {
      statusName: "Completed",
      statusId: 3
    },
    {
      statusName: "Terminated",
      statusId: 4
    },
    {
      statusName: "Cancelled",
      statusId: 8
    },
    {
      statusName: "Email Sent To Set Encryption Password",
      statusId: -4
    },
    {
      statusName: "Delivery Failed",
      statusId: -1
    }
  ];

  messageType: any = [
    {
      messageTypeId: -4,
      messageTypeName: "Secure Tags"
    },
    {
      messageTypeId: -3,
      messageTypeName: "Secure E-Paper"
    },
    {
      messageTypeId: -2,
      messageTypeName: "Tags"
    },
    {
      messageTypeId: -1,
      messageTypeName: "E-Paper"
    }
  ]

  formBuild() {

    this.searchForm = this.formBuilder.group({
      messageID: new FormControl('', [Validators.pattern('^[a-zA-Z0-9]+$')]),
      messageId: new FormControl('', [Validators.pattern('^[a-zA-Z0-9]+$')]),
      senderRecipient: new FormControl('', [Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}')]),
      senderRecipientValue: new FormControl('', []),
      messageType: new FormControl(null, []),
      messageStatus: new FormControl(null, []),
      subject: new FormControl('', []),
      fromDate: new FormControl('', []),
      toDate: new FormControl('', [])
    });

  }

  setValuestoContorls(searchValues: any) {

    // if (searchValues.MessageId != null) {
    //   this.messageData = [{ value: searchValues.MessageId }];
    //   this.messageOptions.next([{ value: searchValues.MessageId }]);
    // }

    // if (searchValues.RecipientAddress != null) {
    //   this.senderRecipientData = [{ value: searchValues.RecipientAddress }];
    //   this.senderRecipientOptions.next([{ value: searchValues.RecipientAddress }]);
    // }

    this.searchForm.controls.messageID.setValue(searchValues.MessageId);

    this.searchForm.controls.senderRecipient.setValue(searchValues.RecipientAddress);

    if (searchValues.MessageType != null) {
      this.searchForm.controls.messageType.setValue(searchValues.MessageType);
    }

    if (searchValues.EsignState != null) {
      this.searchForm.controls.messageStatus.setValue(searchValues.EsignState);
    }
    
    this.searchForm.controls.subject.setValue(searchValues.Subject);

    if (searchValues.DateFrom != null)
      this.searchForm.controls.fromDate.setValue(new Date(searchValues.DateFrom));

    if (searchValues.DateTo != null)
      this.searchForm.controls.toDate.setValue(new Date(searchValues.DateTo));
  }

  getMessageIds(searchValue: any) {

    var value = {
      searchText: searchValue,
      isCompany: this.showField
    };

    //this.messageData = [];


    var res = this.trackingService.apiGetMessageIds(value)
      .subscribe(resp => {
        this.messageData = [];
        if (resp instanceof AppError) {

          //return resp.asObservable();
        }
        else if (resp as ImessageIds) {

          var result = resp as ImessageIds;

          this.messageData = result.resultContent;
        }

      });

      return this.messageData;
  }

  getSenderRecipients(searchValue: any) {

    var value = {
      searchText: searchValue,
      isCompany: this.showField
    };

    this.senderRecipientData = [];

    var res = this.trackingService.apiGetSenderRecipients(value)
      .subscribe(async resp => {
        
        if (res instanceof AppError) {

        }
        else if (resp as IsenderRecipient) {

          var result = resp as IsenderRecipient;

          this.senderRecipientData = result.resultContent;
        }
      });

      return this.senderRecipientData;
  }

  getMessageDataonSearch(){

    this.searchForm.controls.messageId.valueChanges
      .pipe(
        filter(search => !!search),
        tap(() => this.searchingMessage = true),
        takeUntil(this._onDestroy),
        distinctUntilChanged(),
        debounceTime(5000),
        map(search => {

          this.messageOptions= new ReplaySubject<messageIDValue[]>();

          var result= this.getMessageIds(search);          

          if (search instanceof messageIDValue)
            return result.filter((data: any) => data.value.toLowerCase().indexOf(search.value.toLowerCase()) > -1);
          else
            return result;

        }),
        delay(1000),
        takeUntil(this._onDestroy)
      )
      .subscribe(filteredBanks => {
        this.messageOptions= new ReplaySubject<messageIDValue[]>();
        this.messageOptions.next(this.messageData);
        this.searchingMessage = false;
      },
        error => {
          // no errors in our simulated example
          this.searchingMessage = false;
          // handle error...
        });

  }

  getSenderRecipientDataonSearch(){
    
    this.searchForm.controls.senderRecipientValue.valueChanges
    .pipe(
        filter(search => !!search),
        tap(() => this.searchingSR = true),
        takeUntil(this._onSenderDestroy),
        debounceTime(500),
        map(search => {
          
          this.senderRecipientOptions = new ReplaySubject<senderRecipientValue[]>();

          var result = this.getSenderRecipients(search);

          if (search instanceof senderRecipientValue)
            return result.filter((data: any) => data.value.toLowerCase().indexOf(search.value.toLowerCase()) > -1);
          else
            return result;

        }),
        delay(2000),
        takeUntil(this._onSenderDestroy)
      )
      .subscribe(filteredBanks => {
        //this.senderRecipientOptions = new ReplaySubject<senderRecipientValue[]>();
        this.senderRecipientOptions.next(this.senderRecipientData);
        this.searchingSR = false;
      },
        error => {
          // no errors in our simulated example
          this.searchingSR = false;
          // handle error...
        });

  }

  onReset() {

    localStorage.removeItem('searchFilters');

    this.formBuild();
    this.setFromMindate = null;
    this.setToMinDate = null;

    this.setToMaxDate = new Date();
    this.setFromMaxDate = new Date();
    
    this.messageOptions = new ReplaySubject<messageIDValue[]>();
    this.senderRecipientOptions = new ReplaySubject<senderRecipientValue[]>();
    this.searchForm.controls.messageType.setValue('0');
    this.searchForm.controls.messageStatus.setValue('0');
    
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSearch(form: FormGroup) {

    var validate = this.validateForm(form);

    if (validate)
      return;

    var tablePagination = JSON.parse(localStorage.getItem('tablePagination') || '{}');
    var pageIndex = tablePagination.pageIndex != null && tablePagination.pageIndex != '' ? tablePagination.pageIndex == 0 ? 1 : tablePagination.pageIndex + 1 : 1;
    var pageSize = tablePagination.pageSize != null && tablePagination.pageSize != '' ? tablePagination.pageSize : 5;

    var obj = {

      MessageId: form.value.messageID,
      RecipientAddress: form.value.senderRecipient,
      MessageType: form.value.messageType != '0' ? form.value.messageType : null,
      EsignState: form.value.messageStatus != '0' ? form.value.messageStatus : null,
      Subject: form.value.subject != null && form.value.subject != undefined ? form.value.subject.trim() : null,
      DateFrom: form.value.fromDate != null && form.value.fromDate != "" ? format(new Date(form.value.fromDate), 'yyyy-MM-dd') : null,
      DateTo: form.value.toDate != null && form.value.toDate != "" ? format(new Date(form.value.toDate), 'yyyy-MM-dd') : null,
      IsCompany: this.showField,
      PageNumber: pageIndex,
      PageSize: pageSize,
      Column: '',
      Direction: ''
    };

    localStorage.setItem('searchFilters', JSON.stringify(obj));

    this.dialogRef.close(obj as SearchFilter);
  }

  fromDateChange(event: any) {

    if (event.value < new Date()) {
      this.setToMinDate = subDays(event.value, 30);
      this.setToMaxDate = new Date();
    }

  }

  toDateChange(event: any) {

    if (event.value < new Date()) {
      this.setFromMindate = subDays(event.value, 30);
      this.setFromMaxDate = event.value;
    }

  }

  validateForm(searchForm: FormGroup) {

    var isValidate = false;

    if(searchForm.controls.messageID.errors?.pattern && searchForm.value.messageID != ""){
      isValidate = true;
      this.openSnackBar('Please enter valid message id.', '');
    }

    if(searchForm.controls.senderRecipient.errors?.pattern && searchForm.value.senderRecipient != ""){
      isValidate = true;
      this.openSnackBar('Please enter valid email id.', '');
    }

    if (searchForm.value.toDate != "" && searchForm.value.toDate > new Date()) {
      isValidate = true;
      this.openSnackBar('To date cannot be greater than today.', '');
    }

    if (searchForm.value.toDate != "" && searchForm.value.fromDate != ""
      && searchForm.value.toDate > searchForm.value.fromDate) {

      var dateDiff = differenceInDays(searchForm.value.toDate, searchForm.value.fromDate);

      if (dateDiff > 30) {
        isValidate = true;
        this.openSnackBar('Max allowed date range is 30 days', '');
      }
    }

    if (searchForm.value.fromDate > new Date()) {
      isValidate = true;
      this.openSnackBar('From date connot be greater than today.', '');
    }

    
    if (searchForm.value.toDate < searchForm.value.fromDate) {
      this.openSnackBar('To date cannot be less than from date.', '');
      isValidate = true;
    }

    if (searchForm.value.toDate != "" && searchForm.value.fromDate != ""
      && searchForm.value.fromDate > searchForm.value.toDate) {

      var dateDiff = differenceInDays(searchForm.value.fromDate, searchForm.value.toDate);

      if (dateDiff > 30) {
        isValidate = true;
        this.openSnackBar('Max allowed date range is 30 days', '');
      }
    }

    return isValidate;
  }

  onCntrlKeyPress(event: any): any {

    var inputKeyPress = event;

    if (inputKeyPress.currentTarget.id == "messageId") {
      var keyPressCode = event.keyCode != null ? event.keyCode : event.charCode

      if ((keyPressCode >= 65 && keyPressCode <= 90) || (keyPressCode >= 48 && keyPressCode <= 57)
        || (keyPressCode == 9) || (keyPressCode == 8) || (keyPressCode == 127) || (keyPressCode >= 97 && keyPressCode <= 122)
      ) {
        return true;
      }
      else {
        return false;
      }
    }

    if (inputKeyPress.currentTarget.id == "senderrecipientName") {

    }

  }

  onCntrlIdPaste(event: any) {
    
    var inputEvent = event;
    var form = this.searchForm;

    if (inputEvent.inputType == "insertFromPaste") {

      if (event.target.id == "messageId") {
        if (form.controls.messageID.errors?.pattern && event.target.value != "") {
          this.openSnackBar("Please enter valid message id", "");
        }
      }

      if(event.target.id=="senderrecipientName"){
        if (form.controls.senderRecipient.errors?.pattern && event.target.value != "") {
          this.openSnackBar("Please enter valid email id", "");
        }
      }

    }

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();

    this._onSenderDestroy.next();
    this._onSenderDestroy.complete();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }


}