<div class="flex-row-center login-form-min" *ngIf="hdo != 2">
    <div class="contentloader" *ngIf="this.spinner">
            <img src="../assets/images/orange-loader.gif" alt="">
        </div>
    <form novalidate [formGroup]="loginForm">

        <!-- <i *ngIf="this.spinner" class="fa fa-spinner fa-spin"></i> -->
        <!-- *ngIf="this.spinner" -->
        
        <div class="Signin-form" *ngIf="!this.spinner">
            <div *ngIf="!this.spinner">
                <div class="logo1">
                    <!-- <a href="http://www.rmail.com/" title="RMail" rel="home"><img src="../../../assets/images/rmail-logo.png"
                        alt="Rmail Logo"></a> -->
                    <a href="http://www.rmail.com/" title="RMail" rel="home">

                        <img src="{{logoURL}}" alt="RMail Web">

                    </a>
                </div>
            </div>
            <small *ngIf="!this.spinner">Web-Tracking Login</small>

            <ul class="form-wrapper" *ngIf="!this.spinner">
                <li id="unameSec">
                    <!-- User Name: -->
                    <div class="password-container">
                    <input type="email" formControlName="email" id="email" class="form-control"
                        placeholder="Email address" (blur)="onEmailBlur($event)">
                        <i class="fa fa-envelope icon"></i>
                    </div>
                          <div class="text-danger popup" *ngIf="validPatternEmail">
                            <span class="popuptext2" [ngClass]="{'show': (validPatternEmail)}"> 
                                Please provide a valid email address</span>   
                          </div> 
                </li>
                <li id="passSec">
                    <!-- Password: -->
                    <div class="password-container">
                    <input type="password" formControlName="password" id="Password" class="form-control"
                        placeholder="Password" >
                        <i class="fa fa-key" ></i>
                    </div>  
                </li>
              
                <li class="login-links">
                    <div class="p-0">
                        <mat-checkbox class="example-margin" formControlName="rememberMe">Remember me</mat-checkbox>
                    </div>
                    <button type="button" class="forgotpassword" (click)="forgetPassword()">Forgot password?</button>
                </li>
                <li style="margin-top:10px;">
                    <re-captcha formControlName="captcha" name="captcha"
                    (resolved)="resolved($event)" (errored)="errored($event)" 
                    [(ngModel)]="captchaResponse"></re-captcha>
                
                    <div class="alert alert-danger" *ngIf="validGoogleCaptcha">
                        Please validate Captcha
                    </div>
                </li>
                <li class="text-center">  
                    <button (click)="onSubmit(loginForm)"   [disabled]="loginForm.invalid" 
                    [ngClass]="{'disabled-button': loginForm.invalid, 'enabled-button': !loginForm.invalid}"
                     id="btnSubmit">Sign in</button>
                </li>
                <li class="register-here">
                    Don't have an account?
                    <button class="forgotpassword" (click)="redirectToRegister()">Register me</button>
                </li>
                <li *ngIf="isLoading" class="mb-0">
                    <div class="text-center">
                        <i class="fa fa-spinner fa-spin spin-color"></i>
                    </div>
                </li>
                
            <li>
                <div class="or_options text-center">
                    <div class="or_text">
                        <span>Or Sign in </span>
                    </div>
                </div>
                <button type="button" class="btn btn-login btn-secondary btn-SSO" (click)="adportal()">
                    <i class="fa fa-key" style="margin-right: 8px;"></i> Single Sign-on (SSO)
                </button>
            </li>              
            </ul>
        </div>
    </form>

</div>
