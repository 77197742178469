<h2 mat-dialog-title>Cancel Transaction</h2>
<mat-dialog-content>
    <form [formGroup]="cancelForm">
        <div class="form-group cancel-code"> 
            <textarea matInput maxlength="50" placeholder="Please specify the reason for cancellation"
                formControlName="cancelReason" class="form-control input-block">
            </textarea>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-flat-button class="btn-margin" [disabled]="!validate.cancelReason.valid" (click)="onConfirm(cancelForm)"
        color="primary">Submit</button>
    <button mat-flat-button class="btn-margin btn-close" (click)="onClose()">Cancel</button>
</mat-dialog-actions>