import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { IBrandHF } from './auth/iapp-user';
import { UserSessionService } from './common/user-session.service';

@Injectable({
  providedIn: 'root'
})
export class BrandingResolverResolver implements Resolve<boolean> {

  constructor(private authService: AuthService, private user: UserSessionService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    if (localStorage.getItem('brand') == null && localStorage.getItem('brand') == undefined) {

      var userInfo = JSON.parse(localStorage.getItem('user') || '{}')

      return this.authService.getBrand(userInfo.userEmail).pipe(
        map(m => {

          const response = m as IBrandHF;

          const result = response.resultContent;
          const brand = {
            headerHtml: result.headerHtml,
            footerHtml: result.footerHtml
          };
          this.user.setBrand(brand);
        }),

        catchError(error => {

          return of('No data');
        }));
    }

    return of(true);
  }
}
