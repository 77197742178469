import { Injectable } from "@angular/core";
import { DataMFAApiService } from "../common/data-mfa.service";
import { AppConfigService } from "../app-config.service";


@Injectable()
export class MFAService {

    baseUrl: string;
    clientId: string;
    authURL: string;
    tokenURL: string;
    ssoEnabledURL: string;

    constructor(private data: DataMFAApiService, private config: AppConfigService) {
        this.baseUrl = this.config.baseApiUrl;
        this.clientId = this.config.clientId;
        this.authURL = this.config.authURL;
        this.tokenURL = this.config.tokenURL;
        this.ssoEnabledURL = this.config.ssoEnabled;

    }

    getMFALoginSetting(params: any) {

        return this.data.getMFALoginSetting(params);

    }

    generateMFAToken(params: any) {

        var requestObj = {
            EmailAddress: params.emailAddress,
            Password: params.userPassword,
            Client_Id: this.clientId
        }

        return this.data.generateMFAToken(requestObj);
    }

}