

<div class="flex-row-center">
  
    <form novalidate [formGroup]="resetForm" >

        <div>
            <h1 class="logo">
                <a href="http://www.rmail.com/" title="RMail" rel="home">
                    <img src="{{logoURL}}" alt="RMail Web">
                </a>
            </h1>
        </div>
   <div class="card-rs p-5" *ngIf="isPasswordReset"> 
    <div class="form-icon"> <i class="fa fa-check-circle fa-3"></i></div>
        <div class="pswd-msg" *ngIf="isPasswordReset">
            
             <b class="text-green pb-3">Your password has been reset! </b>
             <div class="clear-fix">&nbsp;</div> 
            <b> Please click <a routerLink="/login" class="text-blue">here</a> to Sign In</b>
             </div>
    
    </div>

    <div class="card-rs p-5" *ngIf="!isCodeValid">
        <div class="form-icon">
        <img src="{{errorImageUrl}}"></div>
            <p class="text-center pb-3" > The link you are trying to access has already been used or is no longer valid</p>
    <div class="clear-fix">&nbsp;</div>
         <p class="text-center pb-3">Please request a new link to reset password</p>
         
        </div>


        <!-- <div class="alert alert-danger m-10" *ngIf="validPassword">
            Please enter passsword    
        </div> -->

        <!-- <div class="alert alert-danger  m-10" *ngIf="validConfirmPassword">
            Please enter confirm passsword    
        </div> -->

        <!-- <div class="alert alert-danger  m-10" *ngIf="validPasswordPattern">
            *Password Must contain at least one uppercase character, one lowercase
            character, one special character and one number.
        </div>
    
        <div class="alert alert-danger  m-10" *ngIf="validConfirmPatternPassword">
            *Confirm password Must contain at least one uppercase character, one lowercase
            character, one special character and one number.
        </div> -->
    
        <!-- <div class="alert alert-danger m-10" 
        *ngIf="validMatchPassword">
            Password and Confirm Password must be same 
        </div> -->
        
        <div class="reset-password" *ngIf=" isCodeValid && !isPasswordReset">
            <h4>Create New Password</h4>
            <div class="pw-is-d">
            <h6 class="pull-left">Password requirements:</h6>
            <div class="clear-fix">&nbsp;</div>
            <ul class="w-100 mt15 text-center">
                <li>
                <i [ngClass]="hasUppercase() ? 'fa fa-check checked' : 'fa fa-minus unchecked'" aria-hidden="true"></i>
                <span [ngClass]="hasUppercase() ? 'checked' : 'unchecked1'">&nbsp; &nbsp; At least one uppercase letter</span>
              </li> 

              <li>
                <i [ngClass]="hasLowercase() ? 'fa fa-check checked' : 'fa fa-minus unchecked'" aria-hidden="true"></i>
                <span [ngClass]="hasLowercase() ? 'checked' : 'unchecked1'">&nbsp; &nbsp; At least one lowercase letter</span>
              </li>
              <li>
                <i [ngClass]="hasNumber() ? 'fa fa-check checked' : 'fa fa-minus unchecked'" aria-hidden="true"></i>
                <span [ngClass]="hasNumber() ? 'checked' : 'unchecked1'">&nbsp; &nbsp; At least one number</span>
              </li>
              <li>
                <i [ngClass]="hasSpecialChar() ? 'fa fa-check checked' : 'fa fa-minus unchecked'" aria-hidden="true"></i>
                <span [ngClass]="hasSpecialChar() ? 'checked' : 'unchecked1'">&nbsp; &nbsp; At least one special character</span>
              </li>
              <li>
                <i [ngClass]="isLengthValid() ? 'fa fa-check checked' : 'fa fa-minus unchecked'" aria-hidden="true"></i>
                <span [ngClass]="isLengthValid() ? 'checked' : 'unchecked1'">&nbsp; &nbsp; At least 8 characters in length</span>
              </li>
              </ul>
            </div>
            <ul class="form-wrapper">
                <li class="noerrTag" id="resPassReq">
                    <div class="password-container">
                    <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" class="form-control" name="Password" id="Password" 
                    placeholder="New Password" required (blur)="onPasswordBlur($event)" (keydown.space)="$event.preventDefault()" maxlength="25"
                    minlength="8">
                    <i class="fa fa-key" style="top:11px;"></i>
                    <i class="fa fa-eye password-toggle" (click)="togglePasswordVisibility()" style="left: 310px; top:11px;"></i>
                    </div>
                </li>
                <li class="noerrTag" id="resPassReq">
                    <div class="password-container">
                    <input type="{{ showConfirmPassword ? 'text' : 'password' }}" formControlName="confirmPassword" class="form-control" name="PasswordC" id="PasswordC" 
                    placeholder="Confirm Password" (keydown.space)="$event.preventDefault()" (blur)="onConfirmPasswordBlur($event)" maxlength="25" minlength="8" required>
                    <i class="fa fa-key" style="top:11px;"></i>
                    <i class="fa fa-eye password-toggle" (click)="toggleConfirmPasswordVisibility()" style="left: 310px; top:11px;"></i>
                    <div class="text-danger popup" *ngIf="validMatchPassword">
                        <span class="popuptext" [ngClass]="{'show': (validMatchPassword)}"> 
                            Password and Confirm Password must be same</span>   
                      </div> 
                </div>
                </li>
                <li>
                    <button class="btn btn-login"  [disabled]="resetForm.invalid || !isPasswordValid() || resetForm.value.password != resetForm.value.confirmPassword" title="Click to submit" (click)="resetPassword(resetForm)"
                    [ngClass]="{'disabled-button': resetForm.invalid  || !isPasswordValid() || resetForm.value.password != resetForm.value.confirmPassword, 'enabled-button': !resetForm.invalid || isPasswordValid() || resetForm.value.password == resetForm.value.confirmPassword}">
                        Reset Password
                    </button>
                </li>
            </ul>
        </div>
    </form>  
</div>

    
    
