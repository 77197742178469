import { analyzeAndValidateNgModules } from "@angular/compiler";
import { Component, Inject, OnInit, Optional } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppError } from "src/app/common/app-error";
import { TrackingService } from "src/app/trackingservice/tracking.service";
import { CancelDetails } from "src/app/models/cancel-details.model";
import { RefreshTokenService } from "src/app/common/refreshToken.service";
import { AuthService } from "src/app/auth/auth.service";
import { Router } from "@angular/router";


@Component({
  selector: 'app-cancel-transaction',
  templateUrl: './cancel-transaction.component.html',
  styleUrls: ['./cancel-transaction.component.scss']
})

export class CancelTransactionComponent implements OnInit {

  cancelForm: FormGroup;
  messageId: string;
  trackingKey: string;

  constructor(public dialogRef: MatDialogRef<CancelTransactionComponent>, private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private trackingService: TrackingService,
    private refreshToken: RefreshTokenService, private authService: AuthService,
    private router:Router) {
    this.messageId = data.messageId;
    this.trackingKey = data.trackingKey;
  }

  ngOnInit() {

    this.cancelForm = this.formBuilder.group({
      cancelReason: new FormControl('', [])
    });

  }

  get validate() { return this.cancelForm.controls; }

  onConfirm(form: FormGroup) {

    var formData = form.value;

    var obj = {
      MessageId: this.messageId,
      Reason: formData.cancelReason,
      TrackingKey: this.trackingKey
    }
    
    this.cancelTransaction(obj);
    
  }

  cancelTransaction(obj: any) {

    this.trackingService.apicancelTransaction(obj)
      .subscribe(resp => {

        if (resp instanceof AppError) {

        }
        else {

          var result = resp as CancelDetails;
          this.dialogRef.close(result.resultContent);

        }

      });

  }

  onClose(){
    this.dialogRef.close();
  }

}