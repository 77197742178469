
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable,  of } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';

import { AppError } from './app-error';
import { AppConfigService } from '../app-config.service';
// import { RmailParamService } from '../rmail-send/rmail-param.service';

@Injectable()
export class DataApiService {

  baseUrl: string;
  clientId: string;
  authURL: string;
  tokenURL: string;
  ssoEnabledURL: string;


  constructor(private http: HttpClient, private config: AppConfigService) {
    this.baseUrl = this.config.baseApiUrl;
    this.clientId = this.config.clientId;
    this.authURL = this.config.authURL;
    this.tokenURL = this.config.tokenURL;
    this.ssoEnabledURL = this.config.ssoEnabled;
   }

  get rmailClientId() {
    return this.config.clientId;
  }
  
  apiToken(email: string, pwd: string) {
    //const tokenUrl1 = `${this.baseUrl}/Token`;
    const tokenUrl = `${this.tokenURL}/api/v1/auth/Token`;
    const loginInfo = `grant_type=password&username=${email}&password=${pwd}&Client_ID=${this.clientId}`;

    var obj = {
      emailAddress: email,
      password: decodeURIComponent(pwd),
      //grant_type: 'password',
      client_id: this.clientId
    }

    var load = JSON.stringify(obj);
    
    let headers = new HttpHeaders();

    headers = headers.append('content-type', "application/json");   
     //headers = headers.append('content-type', 'application/x-www-form-urlencoded');
    
    return this.http.post(tokenUrl, load, { headers: headers }).pipe(
      catchError(this.handleError)
    );
  }

  apiRegister(resource: any) {
    const registerUrl = `${this.baseUrl}/api/Account/Register`;

    resource.ConfirmationCallbackUrl = "";

    return this.http.post(registerUrl, JSON.stringify(resource), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );
  }
//   apiResendActivation(email) {
//     const url = `${this.baseUrl}/api/v1/Account/ResendActivationLink`;
//     const resource = {Email: email, ConfirmationCallbackUrl: '' };
//     return this.http.post(url, JSON.stringify(resource), {
//       headers: new HttpHeaders({
//         'Content-Type': 'application/json'
//       })
//     }).pipe(
//       catchError(this.handleError)
//     );
//   }
  apiForgotPassword(reqObj:any) {
    
    const url =  `${this.authURL}/api/v1/Account/ForgotPassword`;
    
    const resource = {
      UserEmailAddress: reqObj.email,
      FromEmailAddress: 'support@rpost.com',
      CallbackUrl: this.config.resetPwdUrl,
      ResetAccountName: 'Web-Tracking',
      ClientId: this.config.clientId,
      LoginURL: reqObj.loginURL
      //Language: appLang
    };

    return this.http.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(
        catchError(this.handleError)
      );

  }
  apiResetPassword(pwd1: string, pwd2: string, code: string) {
    const url =  `${this.authURL}/api/Account/ResetPassword`;
    const resource = {Password:  pwd1, ConfirmPassword: pwd2, Code: code};
    return this.http.post(url, JSON.stringify(resource), {
      headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }).pipe(
        catchError(this.handleError)
      );

  }
    apiValidateCode(code: string){       // S12-1176
      const url =  `${this.authURL}/api/v1/Account/ResetPassword/ValidateCode`;
      const resource = { Code: code};
      return this.http.post(url, resource, {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
        catchError(this.handleError)
      );
    }

  apiGetById(_url: string) {
    // param included in url    
    const url = `${this.baseUrl}/${_url}`;
    return this.http.get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
//   apiPost(_url: string, resource) {
//     // resource is post payload
//     const url = `${this.baseUrl}/${_url}`;
//     const payload = JSON.stringify(resource);
//     return this.http.post(url, payload)
//       .pipe(
//         catchError(this.handleError)
//       );
//   }

//   apiPut(_url: string, resource) {
//     const url = `${this.baseUrl}/${_url}`;
//     const data = JSON.stringify(resource);
//     return this.http.put(url, data)
//       .pipe(
//         catchError(this.handleError)
//       );
//   }
  
  apiGetEsignDetails(_url: string, searchData: any) {
    
    const url = `${this.baseUrl}/${_url}`;

    let headers = new HttpHeaders();
    headers = headers.append('content-type', "application/json");    

    let params = new HttpParams().set("messagesModel", JSON.stringify(searchData));
    
    return this.http.post(url, JSON.stringify(searchData), { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  apiGetMessageDetails(_url:string){

    const url = `${this.baseUrl}/${_url}`;

    let headers = new HttpHeaders();
    headers = headers.append('content-type', "application/json");

    return this.http.post(url, { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  apiGetTrackingAccess(_url: string) {

    const url = `${this.baseUrl}/${_url}`;

    let headers = new HttpHeaders();
    headers = headers.append('content-type', "application/json");

    return this.http.get(url, { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  apiCancelTransaction(_url: string, data: any) {

    const url = `${this.baseUrl}/${_url}`;

    let headers = new HttpHeaders();
    headers = headers.append('content-type', "application/json");

    return this.http.post(url, JSON.stringify(data), { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  apiGetMessageIds(_url: string, data: any) {

    const url = `${this.baseUrl}/${_url}`;

    let headers = new HttpHeaders();
    headers = headers.append('content-type', "application/json");

    return this.http.post(url, JSON.stringify(data), { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  apiGetSenderRecipients(_url: string, data: any){

    const url = `${this.baseUrl}/${_url}`;

    let headers = new HttpHeaders();
    headers = headers.append('content-type', "application/json");

    return this.http.post(url, JSON.stringify(data), { headers: headers })
      .pipe(
        catchError(this.handleError)
      );
  }

  apiDownloadCSV(_url: string, data: any) {

    const url = `${this.baseUrl}/${_url}`;

    let headers = new HttpHeaders();
    headers = headers.append('content-type', "application/json");
    
    return this.http.post(url, JSON.stringify(data), { headers: headers, responseType: 'blob'})
      .pipe(
        catchError(this.handleError)
      );
  }

  refreshToken(): any {

    var userDetail = localStorage.getItem('user') || null;

    if (userDetail !== null && userDetail !== 'null') {

      var user = JSON.parse(userDetail);

      if (user) {

        var obj = {
          access_token: user.token,
          refresh_token: user.refresh_token
        }

        var load = JSON.stringify(obj);

        var tokenUrl = `${this.tokenURL}/api/v1/auth/RefreshToken`;

        return this.http.post(tokenUrl, load, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
          catchError(this.handleError)
        );
      }
    }
  }

  validateToken(): any {

    var userDetail = localStorage.getItem('user') || null;

    if (userDetail !== null && userDetail !== 'null') {

      var user = JSON.parse(userDetail);

      if (user) {

        var obj = {
          access_Token: user.token,
          client_Id: this.clientId,
          emailAddress: user.userEmail
        }

        var load = JSON.stringify(obj);
        
        var tokenUrl = `${this.tokenURL}/api/v1/auth/ValidateToken`;

        return this.http.post(tokenUrl, load, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
          catchError(this.handleError)
        );
      }
    }
  }

  revokeToken(): any {

    var userDetail = localStorage.getItem('user') || null;

    if (userDetail !== null && userDetail !== 'null') {

      var user = JSON.parse(userDetail);

      if (user) {

        var obj = {
          access_token: user.token
        }

        var load = JSON.stringify(obj);

        var tokenUrl = `${this.tokenURL}/api/v1/auth/Token/Revoke`;

        return this.http.post(tokenUrl, load, {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }).pipe(
          catchError(this.handleError)
        );
      }
    }
  }

  azurePortalExtToken(params: any) {

    var tokenUrl = `${this.tokenURL}/api/v1/auth/ExtToken`;
    var token = '';

    var obj = {
      provider_Access_Token: params.provider_Access_Token,
      provider: params.ssoType,
      client_id: this.clientId
    }

    var load = JSON.stringify(obj);

    return this.http.post(tokenUrl, load, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );

  }

  googleCaptchaVerificationToken(params: any) {

    var tokenUrl = `${this.baseUrl}/api/account/ValidateGoogleCaptchaToken`;
    
    var obj = {
      Secret: this.config.googleSecretKey,
      Response: params
    }

    var load = JSON.stringify(obj);

    return this.http.post(tokenUrl, load, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );

  }

  ssoEnabledCheck(emailAddress: any) {

    var tokenUrl = `${this.tokenURL}/api/v1/auth/SsoEnabled?emailAddress=${emailAddress}`;

    return this.http.get(tokenUrl,{
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );
  }

  //S12-1158
  getIPAddress(){

    return this.http.get("https://ipv4.jsonip.com").pipe(
      catchError(this.handleError)
    );
  }

  sendManualReminder(_url: string, params: any) {

    const url = `${this.baseUrl}/${_url}`;

    var load = JSON.stringify(params);

    return this.http.post(url, load, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );

  }

  getReminderList(_url: string, params: any){

    const url = `${this.baseUrl}/${_url}`;

    var load = JSON.stringify(params);

    return this.http.post(url, load, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(
      catchError(this.handleError)
    );

  }

  private handleError(err: HttpErrorResponse ): Observable<AppError> {
    
    const appError = new AppError(err);
    const errorStatus = err.status;
    const errorStatusText = err.statusText;
    const error: any = err.error;
    let errorMsg = '';
    let msgId = '';

    if (error != null && error != undefined) {
      if (error.Message !== undefined && Array.isArray(error.Message)) {
        errorMsg = err.error.Message[0].Message;
        msgId = err.error.Message[0].MessageId;
      } else {
        if (error.error_description) {
          errorMsg = error.error_description;
        } else {
          if (error.Message) {
            errorMsg = error.Message;
            msgId = (error.MessageId) ? error.MessageId : '';
          } else {
            errorMsg = errorStatusText;
          }
        }
      }
    }

    const retMsg = `Error (${errorStatus}): ${errorMsg}`;
    appError.Message = retMsg;
    appError.MessageId = msgId;

    return of(appError);
  }
}
export interface IApiResponse {
  Message: any;
  ResultContent: any;
  Status: string;
  StatusCode: number;
  StatusText: string;
  MessageId: string;
}
