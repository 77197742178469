
export class IresetPassword {
    Message: any
    ResultContent: resetPassword
    Status: string;
    StatusCode: number;
    StatusText: number
}

export class resetPassword {
    value: string;
}