<h2 mat-dialog-title>Reset your password</h2>
<mat-dialog-content>
    A link to reset your password will be sent to the email address.
    <form novalidate [formGroup]="forgotForm">
        
            <div class="rester-form for-pop-pw">
        <ul class="form-wrapper">
            <li >
            <input type="email" formControlName="email" class="form-control"
                placeholder="Please enter your email address" (blur)="onEmailBlur($event)" />
            <!-- <div style="color: red;" *ngIf="(validate.email.dirty||validate.email.touched)&&validate.email.invalid&&validate.email.errors?.required">
                Email is required
            </div>
            <div style="color: red;" *ngIf="validate.email.errors?.pattern">
                Please provide a valid email address
            </div> -->
            <div class="text-danger popup" *ngIf="validEmail">
                <span class="popuptext" [ngClass]="{'show': (validEmail)}"> 
                    Please enter valid email</span>   
              </div> 
        </li>
        </ul>
    </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions>
    <span class="m-auto">
    <button class="btn btn-blue btn-margin" (click)="onConfirm(forgotForm)">Submit</button>
    <button class="btn-margin btn btn-close"(click)="onClose()" color="primary">Close</button>
    </span>
</mat-dialog-actions>