import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalPopupService } from 'src/app/providers/modalPopup.service';
import { TrackingService } from 'src/app/trackingservice/tracking.service';
import { SearchFilterComponent } from '../search-filter/search-filter.component';
import { format } from 'date-fns'
import { messageDetails } from 'src/app/models/message-details.model';
import { AuthService } from 'src/app/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchFilter } from 'src/app/models/search-filter.model';
import { MatTabGroup } from '@angular/material/tabs';
import { UserSessionService } from 'src/app/common/user-session.service';
import { IAppUser, ITracking } from 'src/app/auth/iapp-user';
import { IPageEvent, MessageDetail, SortEvent } from 'src/app/models/tracking-details.model';
import * as fileSaver from 'file-saver';
import { AppError } from 'src/app/common/app-error';
import { map } from 'rxjs/operators';
import { RefreshTokenService } from 'src/app/common/refreshToken.service';
import { MouseEventsService } from 'src/app/common/mouseevents.service';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-tracking-esign',
  templateUrl: './tracking-esign.component.html',
  styleUrls: ['./tracking-esign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackingEsignComponent implements AfterViewInit, OnInit {

  dialogRef: MatDialogRef<any>;

  dataSource: MatTableDataSource<messageDetails>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('matTab') tabGroup: MatTabGroup;

  gridData: messageDetails[] = [];

  selectedTab: boolean = true;
  showRecipientSender: boolean = false;
  columnSort: SortEvent;

  constructor(private modalPopupService: ModalPopupService, private formBuilder: FormBuilder,
    private router: Router, private trackingService: TrackingService, private authService: AuthService,
    public snackBar: MatSnackBar, private userService: UserSessionService, private route: ActivatedRoute,
    private refreshToken: RefreshTokenService, private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute, private mouseEventService: MouseEventsService,
    private appconfig: AppConfigService) {

  }

  columns = [
    { field: 'messageId', header: 'Message Id' },
    { field: 'signedSignersCount', header: 'Signed/Signers' },
    { field: 'sender', header: 'Sender' },
    { field: 'subject', header: 'Subject' },
    { field: 'esignType', header: 'Esign Type' },
    { field: 'status', header: 'Status' },
    { field: 'date', header: 'Sent (UTC)' },
    { field: 'timeSigned', header: 'Last Signed (UTC)' }
  ];

  headers: string[];
  headersFilters: any;
  filtersModel = [];
  filterKeys: { [key: string]: any } = {};
  toggleFilters = true;
  getAccess: any;
  isLoading: boolean = false;
  todayDate = format(new Date(new Date()), 'dd/MM/yyyy');
  tabSelected: number = -1;
  title: string = "";
  pageEvent: IPageEvent;
  noData: any;
  externallogin: any;
  totalRecords: number = 0;
  username: string;  //S12-1194
  showDropdown = false;
  trackingesign: any;
  branding:any;

  ngOnInit() {

    this.activatedRoute.data.subscribe((response: any) => {
      
    });

    var access;
    this.columnSort = new SortEvent();

    this.getAccess = JSON.parse(this.userService.getTrackingAccess() || '{}') as ITracking;
    this.externallogin = JSON.parse(localStorage.getItem('user') || '{}') as IAppUser;

    this.showRecipientSender = this.getAccess.webTrackingAccess;
    this.username = this.externallogin.userEmail; //S12-1194
    if (localStorage.getItem('tabSelected') != null) {
      access = Number(localStorage.getItem('tabSelected')) == 0 ? true : false;
      this.tabSelected = Number(localStorage.getItem('tabSelected'));
      this.showRecipientSender = access
    }
    else
      access = this.getAccess.webTrackingAccess;

    this.trackingData(access);

    this.selectedTab = this.getAccess.webTrackingAccess;

    if (localStorage.getItem('tabSelected') != null) {
      access = Number(localStorage.getItem('tabSelected')) == 0 ? true : false;
      this.tabSelected = Number(localStorage.getItem('tabSelected'));
      this.showRecipientSender = access
    }
    else
      this.tabSelected = this.getAccess.webTrackingAccess === true ? 0 : 1;

    if (this.selectedTab != undefined)
      localStorage.setItem('AdminAccess', this.selectedTab.toString());

    this.getcolumns(this.selectedTab);

    if (this.selectedTab)
      this.title = "Track RMail";
    else
      this.title = "Track E-Sign";

      this.mouseEventService.mouseMove$.subscribe(event => {
        
        // Send the message
        const targetOrigin = this.appconfig.rsuitUrlLink;
        window.parent.postMessage({ event: 'MouseMove', message: 'TrackEsign' }, targetOrigin);
      });

      this.mouseEventService.click$.subscribe(event => {
        
        // Send the message
        const targetOrigin = this.appconfig.rsuitUrlLink;
        window.parent.postMessage({ event: 'MouseClick', message: 'TrackEsign' }, targetOrigin);

      });

      this.mouseEventService.scroll$.subscribe((event:Event)=>{
        
        // Send the message
        const targetOrigin = this.appconfig.rsuitUrlLink;
        if (window.origin == this.appconfig.rsuitUrlLink)
          window.parent.postMessage({ event: 'MouseScroll', message: 'TrackEsign' }, targetOrigin);

      });

  }

  ngAfterViewInit() {

    if (localStorage.getItem('tablePagination') != null) {

      var tablePagination = JSON.parse(localStorage.getItem('tablePagination') || '{}') as IPageEvent;
      this.paginator.pageIndex = tablePagination.pageIndex
      this.paginator.pageSize = tablePagination.pageSize;

    }

  }

  getcolumns(selectedTab: any) {

    var col: string[] = [];

    this.columns.map(function (item, value) {

      if (!selectedTab && item.field != "sender") {
        col.push(item.field);
      }

      if (selectedTab) {
        col.push(item.field);
      }

    });

    this.headers = col;
    this.headersFilters = this.headers.map((x, i) => x + '_' + i);
  }

  trackingData(companyAccess: any) {

    var searchValues = new SearchFilter;
    var pageEvent = null;

    if (localStorage.getItem('searchFilters') != null)
      searchValues = JSON.parse(localStorage.getItem('searchFilters') || '{}') as SearchFilter;

    if (localStorage.getItem('tablePagination') != null)
      pageEvent = JSON.parse(localStorage.getItem('tablePagination') || '');


    var obj = {
      MessageId: searchValues.MessageId != null && searchValues.MessageId != undefined ? searchValues.MessageId : null,
      RecipientAddress: searchValues.RecipientAddress != null && searchValues.RecipientAddress != undefined ? searchValues.RecipientAddress : null,
      MessageType: searchValues.MessageType != null && searchValues.MessageType != undefined ? searchValues.MessageType : null,
      EsignState: searchValues.EsignState != null && searchValues.EsignState != undefined ? searchValues.EsignState : null,
      Subject: searchValues.Subject != null && searchValues.Subject != undefined ? searchValues.Subject.trim() : '',
      DateFrom: searchValues.DateFrom != null && searchValues.DateFrom != undefined ? searchValues.DateFrom : null,
      DateTo: searchValues.DateTo != null && searchValues.DateTo != undefined ? searchValues.DateTo : null,
      IsCompany: companyAccess,
      PageNumber: pageEvent != null && pageEvent != '' ? pageEvent.pageIndex == 0 ? 1 : pageEvent.pageIndex + 1 : 1,
      PageSize: pageEvent != null && pageEvent != '' ? pageEvent.pageSize : 5,
      Column: this.columnSort.Column,
      Direction: this.columnSort.Direction
    }

    this.getTrackingData(obj as SearchFilter);

  }

  getTrackingData(data: SearchFilter) {

    this.gridData = [];
    this.dataSource = new MatTableDataSource<messageDetails>();

    this.isLoading = true;

    if (localStorage.getItem('tabSelected') != null) {
      var selectedtabindex = localStorage.getItem('tabSelected');
      this.tabSelected = selectedtabindex === "1" ? 1 : 0;
    }

    var res = this.trackingService.getEsignDetails(data)
      .subscribe(resp => {

        this.gridData = [];
        this.dataSource = new MatTableDataSource<messageDetails>();

        this.isLoading = false;

        if (resp != null && resp != undefined && resp.success) {

          this.totalRecords = resp.resultContent[0]?.totalRecords;

          resp.resultContent.forEach((item: any) => {

            var esignStatus = item.deliveryStatus == 1 && item.eSignStatus != "CancelledTransaction" && item.eSignStatus != "Incomplete" && item.eSignStatus != "CompletedOnDecline" ? "Failed" : item.eSignStatus;

            var obj = {
              trackingId: item.webTrackingId,
              messageId: item.messageId,
              date: item.date,
              esignType: this.getEsingType(item.serviceType),
              sender: item.senderAddress,
              subject: item.subject,
              status: esignStatus,
              deliveryStatus: item.deliveryStatus,
              signedSignersCount: item.signedSignersCount,
              timeSigned: item.timeSigned
            }

            this.gridData.push(obj);

          });

          this.dataSource = new MatTableDataSource(this.gridData);
          this.noData = this.dataSource.connect().pipe(map(data => data.length === 0));
          this.dataSource.sort = this.sort;

          // this.dataSource.sortingDataAccessor = (item, property) => {
          //   switch (property) {
          //     case 'date': return new Date(item.date);
          //     case 'status': return this.getStatusSort(item.status)
          //     default: return (item as any)[property];
          //   }
          // };

          this.cdr.detectChanges();

        }
        else {

          this.refreshToken.stopTimer();
          this.authService.logout();
          var azureAccess = JSON.parse(localStorage.getItem('azureAccess') || '""');

          if (azureAccess != "" && azureAccess != null) {
            this.authService.azureLogOut();
          }
          else {
            this.router.navigate(['/login']);
          }
        }
      });
  }

  getEsingType(esignType: string) {

    var esingTypeValue: string = "";

    switch (esignType) {
      case "EPaper":
        esingTypeValue = "E-Paper";
        break;
      case "SmartTags":
        esingTypeValue = "Tags";
        break;
      case "SecureSmartTags":
        esingTypeValue = "Secure Tags";
        break;
      case "SecureEPaper":
        esingTypeValue = "Secure E-Paper";
        break;
      default:
        esingTypeValue = "";
        break;
    }

    return esingTypeValue;
  }

  getStatusSort(status: string) {

    var statusSortText = "";

    switch (status) {
      case "Completed":
        statusSortText = status;
        break;
      case "SubmittedForSignature":
        statusSortText = "Submitted For Signature";
        break;
      case "SignatureInProgress":
        statusSortText = "Signature In Progress";
        break;
      case "CancelledTransaction":
        statusSortText = "Cancelled";
        break;
      case "CompletedOnDecline":
        statusSortText = "Terminated";
        break;
      case "EmailSentToSetEncryptionPassword":
        statusSortText = "Email Sent To Set Encryption Password";
        break;
      default:
        statusSortText = status;
        break;
    }

    return statusSortText;
  }

  getRecord(data: any) {

    if (this.tabGroup != null && this.tabGroup != undefined)
      localStorage.setItem('tabSelected', JSON.stringify(this.tabGroup.selectedIndex));

    this.router.navigate(['/messagedetails', data]);
  }

  onSortColumnData(event: any) {

    this.columnSort.Column = event.active;
    this.columnSort.Direction = event.direction;
    this.trackingData(this.tabSelected === 1 ? false : true);
  }

  onPaginateChange(event: any) {

    this.pageEvent = event;
    localStorage.setItem('tablePagination', JSON.stringify(this.pageEvent));

    this.trackingData(this.tabSelected === 1 ? false : true);

  }

  selectedTabValue(event: any) {

    this.tabSelected = event.index;
    localStorage.setItem('tabSelected', JSON.stringify(event.index));
    localStorage.removeItem('searchFilters');

    if (localStorage.getItem('tablePagination') != null) {
      var pageEvent = JSON.parse(localStorage.getItem('tablePagination') || '{}') as IPageEvent;
      pageEvent.pageIndex = 0;
      pageEvent.pageSize = 5;
      localStorage.setItem('tablePagination', JSON.stringify(pageEvent));
    }

    if (event.index == 0) {
      this.title = "Track RMail";
      this.showRecipientSender = true;
      this.getcolumns(true);
      this.trackingData(true);
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = 0;
    }
    else {
      this.title = "Track E-Sign";
      this.showRecipientSender = false;
      this.getcolumns(false);
      this.trackingData(false);
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = 0;
    }
  }

  filter() {

    this.dialogRef = this.modalPopupService.openPopup<SearchFilterComponent>(
      SearchFilterComponent,
      { showField: this.showRecipientSender }
    );
    this.dialogRef.afterClosed().subscribe(result => {

      if (result as SearchFilter) {

        this.isLoading = true;
        this.getTrackingData(result);
      }
    });

  }

  print() {
    this.closeDropdown();
    this.userService.timeOut();
  }

  downloadCSV() {

    var searchObj = new SearchFilter;

    if (localStorage.getItem('searchFilters') != null)
      searchObj = JSON.parse(localStorage.getItem('searchFilters') || "");

    var obj = {
      subject: searchObj?.Subject,
      messageId: searchObj?.MessageId,
      esignState: searchObj?.EsignState,
      dateFrom: searchObj?.DateFrom,
      dateTo: searchObj?.DateTo,
      messageType: searchObj?.MessageType,
      recipientAddress: searchObj?.RecipientAddress,
      isCompany: this.showRecipientSender
    }

    this.getDownloadData(obj);

  }

  getDownloadData(obj: any) {

    var tokenValidate = this.refreshToken.checkTokenExpiration();

    this.downloadCSVData(obj);

  }

  downloadCSVData(obj: any) {

    this.trackingService.apiDownloadCSV(obj).subscribe((resp: any) => {

      if (resp instanceof AppError) {
        const msg: string = resp.Message;
        this.openSnackBar('Error occured while downloading.', '');
      }
      else {

        var today = format(new Date(), 'MM/dd/yyyy');

        let blob: any = new Blob([resp], { type: 'text/csv; charset=utf-8' });

        fileSaver.saveAs(blob, 'TrackingData_' + today + '.csv');
      }

    });
  }

  logout() {

    this.refreshToken.stopTimer();

    if (this.userService.getAzureAccess()) {

      this.authService.azureLogOut();
    }
    else {
      this.authService.getRevokeToken().subscribe((resp: any) => {

        if (!(resp instanceof AppError)) {

          this.authService.logout();
          this.router.navigate(['/login']);
        }
        else {
          this.router.navigate(['/login']);
        }
      });
    }
  }

  clearFilters() {
    this.filtersModel = [];
    this.filterKeys = {};
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      verticalPosition: 'top'
    });
  }
  //S12-1194
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }
  // //To close dropdown when click on outside
  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event): void {
    const target = event.target as HTMLElement;
    const dropdownElement = document.querySelector('.dropdown');

    if (this.showDropdown && !dropdownElement?.contains(target)) {
      this.closeDropdown();
    }
  }

  closeDropdown(): void {
    this.showDropdown = false;
  }

  onMouseMove(event: MouseEvent) {

    if (window.origin == this.appconfig.resetPwdUrl)
      this.mouseEventService.emitMouseMove(event);
  }

  onHandleClick(event: MouseEvent){

    if (window.origin == this.appconfig.resetPwdUrl)
      this.mouseEventService.emitMouseClick(event);
  }

}

