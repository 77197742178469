<h2 mat-dialog-title>Advanced Search</h2>
<mat-dialog-content>

  <div>
    <form [formGroup]="searchForm" class="adv-src-wrapper">

      <ul class="adv-src-form">
        <li>
          Message ID:

          <input type="text" id="messageId" formControlName="messageID" 
          class="form-control input-block" maxlength="128"
            placeholder="Please enter message id" (keypress)="onCntrlKeyPress($event)" 
            (input)="onCntrlIdPaste($event)" />
          <!-- <mat-form-field>
            <mat-select formControlName="messageID" placeholder="Please enter message ID">
              <mat-option>
                <ngx-mat-select-search formControlName="messageId" [searching]="searchingMessage"
                [noEntriesFoundLabel]="messageData!=''? 'Search is in-progress':'no matching record found'" 
                placeholderLabel="Enter message ID" >
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let option of messageOptions | async" [value]="option">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </li>
        <li *ngIf="showField">
          Sender / Recipient:

          <input type="text" id="senderrecipientName" formControlName="senderRecipient" 
          class="form-control input-block" maxlength="255"
            placeholder="Please enter sender / recipient id" 
            (keypress)="onCntrlKeyPress($event)" (input)="onCntrlIdPaste($event)" />
          <!-- <mat-form-field>
            <mat-select formControlName="senderRecipient" placeholder="Please enter Sender / Recipient ID">
              <mat-option>
                <ngx-mat-select-search formControlName="senderRecipientValue" [searching]="searchingSR"
                placeholderLabel="Enter Sender / Recipient ID" noEntriesFoundLabel="'no matching record found'">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let option of senderRecipientOptions | async" [value]="option">
                {{option.value}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </li>
        <li>
          Message Type:
          <mat-form-field>
            <mat-select name="messageType" formControlName="messageType">
              <mat-option value=0>All</mat-option>
              <mat-option *ngFor="let mType of messageType" [value]="mType.messageTypeId">
                {{mType?.messageTypeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li>
          Message Status:
          <mat-form-field>
            <mat-select name="messageStatus" formControlName="messageStatus">
              <mat-option value=0>All</mat-option>
              <mat-option *ngFor="let status of messagestatus" [value]="status.statusId">
                {{status.statusName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li>
          Subject:
          <input type="text" formControlName="subject" class="form-control input-block"
            placeholder="Please enter subject" />
        </li>
        <li>From Date:
          <mat-form-field appearance="fill">

            <input matInput [matDatepicker]="fromDate" placeholder="Choose a date" [max]="setFromMaxDate"
              [min]="setFromMindate" formControlName="fromDate" (dateChange)="fromDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
            <mat-datepicker #fromDate></mat-datepicker>
          </mat-form-field>
        </li>
        <li>To Date:
          <mat-form-field appearance="fill">

            <input matInput [matDatepicker]="toDate" placeholder="Choose a date" formControlName="toDate"
              [min]="setToMinDate" [max]="setToMaxDate" (dateChange)="toDateChange($event)">
            <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
            <mat-datepicker #toDate></mat-datepicker>
          </mat-form-field>
          (Max allowed date range is 30 days)
        </li>
        <li>
          
        </li>
      </ul>
    </form>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button class="btn-margin" (click)="onSearch(searchForm)" color="primary">Apply</button>
  <button mat-flat-button class="btn-margin" class="btn-reset" (click)="onReset()">Reset</button>


  <button mat-flat-button class="btn-margin" class="btn-close" (click)="onCancel()">Cancel</button>
</mat-dialog-actions>