<app-header *ngIf="!externallogin.isExternalLogin"></app-header>
<div (mousemove)="onMouseMove($event)" (click)="onHandleClick($event)">
   <!-- <div class="content-wrap"> -->
  <div class="inner-header">
    <div class="pull-left">
      <h5>Track E-Sign Messages</h5>
    </div>
    <div class="pull-right mr-16">
      <ul>
        <li><i class="fa fa-download btn-nav" (click)="downloadCSV()" title="Download CSV File"></i></li>
        <li><i class="fa fa-filter btn-nav" (click)="filter()" title="Advance Search"></i></li>
        <li><i class="fa fa-print btn-nav" (click)="print()" title="Print"></i></li>
        <li>
          <div class="dropdown btn-nav" (click)="toggleDropdown()" [class.open]="showDropdown">
            <i class="fa fa-user ml-7 btn-nav dropdown-toggle"></i>
            <div class="dropdown-menu top-triangle" *ngIf="showDropdown" role="menu">
             <div class="user-dropdown">
               <i class="fa fa-user ml-6"></i>
               <span class="username">{{username}}</span>
             </div>
             <div  class="signout-dropdown" *ngIf="!externallogin.isExternalLogin" (click)="logout()">
               <i class="fa fa-sign-out ml-7"></i>
               <span class="signout-ml-3">Sign Out</span>
             </div>
             </div>
             </div>
        </li>
      </ul>
      <!-- <i *ngIf="!externallogin.isExternalLogin" class="fa fa-sign-out btn-nav" (click)="logout()" title="Logout"></i> -->
    </div>
  </div>
<div class="content-wrap">
  <div class="page-container mh-80">
    <div class="tabs-panel">
      <mat-tab-group *ngIf="selectedTab" #matTab (selectedTabChange)="selectedTabValue($event)"
        [selectedIndex]="tabSelected" class="tab-wrap">
        <mat-tab label="Company"></mat-tab>
        <mat-tab label="Personal"></mat-tab>
      </mat-tab-group>
    </div>

    <li *ngIf="isLoading">
      <div class="text-center">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
    </li>

    <div class="table-striped table-res">
      <div class="table-h">
      <table mat-table [dataSource]="dataSource" class="table-striped" (matSortChange)="onSortColumnData($event)" matTableExporter #exporter="matTableExporter" matSort>
        <ng-container matColumnDef="messageId">
          <th mat-header-cell *matHeaderCellDef class="tw-30p" > Message ID</th>
          <td mat-cell *matCellDef="let element" class="tw-30p">
            <a (click)="getRecord(element.trackingId)" class="alink">{{element.messageId}}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="signedSignersCount">
          <th mat-header-cell *matHeaderCellDef class="tw-15p" mat-sort-header [title]="'Signed - Number of recipients signed' + '\n' + 'Signer - Total number of recipients for transaction'"> Signed/Signers
            <i class="fa fa-info-circle mt-1" data-placement="right" data-toggle="tooltip"></i>
          </th>
          <td mat-cell *matCellDef="let element" class="tw-15p sender-s" style="text-align: center;">
            <span class="truncate-text sender-s">{{element.signedSignersCount}}</span>
          </td>
        </ng-container>
                      
        

        <ng-container matColumnDef="sender" *ngIf="selectedTab">
          <th mat-header-cell *matHeaderCellDef class="tw-15p" mat-sort-header> Sender</th>
          <!--<td mat-cell *matCellDef="let element" class="tw-15p">
            {{element.sender}}
          </td> -->
          <td mat-cell *matCellDef="let element" class="tw-15p sender-s"> 
            <!-- <span class="truncate-text" *ngIf="element.sender.length >= 25">
              {{element.sender| slice:0:25}}...</span>
              <span class="truncate-text" *ngIf="element.sender.length < 25">{{element.sender}}</span> -->
              <span class="truncate-text sender-s">{{element.sender}}</span>
         </td> 
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef class="tw-31p font-bold"> Subject </th>
          <td mat-cell *matCellDef="let element" class="tw-31p" id="data">
            <span class="truncate-text subject-p" data-title="{{element.subject }}" *ngIf="element.subject.length >= 55">{{element.subject| slice:0:55}}...
            </span>
            <span class="truncate-text subject-c" *ngIf="element.subject.length < 55">{{element.subject}}
            </span>
          </td>
        </ng-container>
        
        <!-- Weight Column -->
        <ng-container matColumnDef="esignType">
          <th mat-header-cell *matHeaderCellDef class="tw-10p" style="min-width: 80px;" mat-sort-header> E-Sign Type </th>
          <td mat-cell *matCellDef="let element" class="tw-10p"> {{element.esignType}} </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="tw-14p font-bold" mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element" class="tw-14p">
            <!-- <a (click)="getRecord(element.trackingId)" class="alink">{{element.messageId}}</a> -->
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='Completed' || element.status=='ProcessingSignedContract'" class="status-info Completed"> Completed </a>
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='SubmittedForSignature'" class="status-info SubmittedForSignature"> Submitted For Signature </a>
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='SignatureInProgress'"  class="status-info SignatureInProgress"> Signature In Progress </a>
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='CancelledTransaction'" class="status-info CancelledTransaction"> Cancelled </a>
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='CompletedOnDecline'" class="status-info CancelledTransaction">Terminated </a>
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='EmailSentToSetEncryptionPassword'" class="status-info CancelledTransaction">Email Sent To Set Encryption Password </a>
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='Incomplete'" class="status-info CancelledTransaction"> In Complete </a>
            <a (click)="getRecord(element.trackingId)" *ngIf="element.status=='Failed'" class="status-info DeliveyFailed">Delivery Failed </a>
            <!-- <span *ngIf="element.status=='Completed' || element.status=='ProcessingSignedContract'" class="status-info Completed"> Completed </span>
            <span *ngIf="element.status=='SubmittedForSignature'" class="status-info SubmittedForSignature"> Submitted For Signature </span>
            <span *ngIf="element.status=='SignatureInProgress'"  class="status-info SignatureInProgress"> Signature In Progress </span>
            <span *ngIf="element.status=='CancelledTransaction'" class="status-info CancelledTransaction"> Cancelled </span>
            <span *ngIf="element.status=='CompletedOnDecline'" class="status-info CancelledTransaction">Terminated </span> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="tw-19p" style="min-width: 100px;" mat-sort-header> Sent (UTC) </th>
          <td mat-cell *matCellDef="let element" class="tw-19p"> {{element.date|date:'dd/MM/yyyy h:mm a'}} </td>
        </ng-container>

        <ng-container matColumnDef="timeSigned">
          <th mat-header-cell *matHeaderCellDef class="tw-19p" style="min-width: 100px;" mat-sort-header> Last Signed (UTC) </th>
          <td mat-cell *matCellDef="let element" class="tw-19p"> {{element.timeSigned|date:'dd/MM/yyyy h:mm a'}} </td>
        </ng-container>
        <ng-container *ngFor="let headersFilter of headersFilters; let i=index" [matColumnDef]="headersFilter">
          <th mat-header-cell *matHeaderCellDef>

          </th>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="headers; sticky: true"></tr>

        <!-- <tr mat-header-row class="no-default-height" *matHeaderRowDef="headersFilters; sticky: true"></tr> -->

        <tr mat-row *matRowDef="let row; columns: headers;">
        </tr>

      </table>
    </div>
      

    <div *ngIf="noData | async" class="no-records">
      No records found
    </div>
  </div>
  
  <div class="pgn">
    <div class="date-range" *ngIf="gridData.length>0" >Displaying records from the past 30 days.
    </div>
    <div class="pagination-pannel" >
      <mat-paginator [length]="totalRecords" style="padding-right:5px; font-weight: bold;"
        [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="pagenation" (page)="onPaginateChange($event)">
      </mat-paginator>
    </div>

</div>
</div>
</div>
</div>
<app-footer *ngIf="!externallogin.isExternalLogin"></app-footer>

<script src="https://code.jquery.com/jquery-3.6.0.min.js"
    integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossorigin="anonymous">
    </script>

<script>
    $(document).ready(function () {
        
        var referrer = document.referrer;
        
        function disableBack() {
            window.history.forward()
        }
        window.onload = disableBack();
        window.onpageshow = function (e) {
            
            if (e.persisted)
                disableBack();
        }
    });
</script>